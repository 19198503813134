import React, {FC} from "react";
import s from "./ValidModal.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import strings from "../../../util/i18n/strings";

type Props = {
  onClose: () => void
}

const ValidModal: FC<Props> = ({onClose}) => (
  <div className={s.InstallDialog}>
    <div className={s.bg} onClick={onClose}/>
    <div className={s.content}>
      <div className={s.closeIcon} onClick={onClose}>
        <CloseIcon/>
      </div>
      <div className={s.details}>
        <p>
          {strings.login.alert_message_incorrect_number.split('/underline').map((el: string, index: number) => {
            if (el.includes('965 123 45 678')) {
              return (
                <span key={index}>
                <span style={{textDecoration: 'underline'}}>{el}</span>
              </span>
              )
            } else {
              return (
                <span key={index}>
              {el}
            </span>
              )
            }
          })}
        </p>
      </div>
    </div>
  </div>
)

export default ValidModal;
