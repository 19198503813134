import {ACCESS_TOKEN_KEY, AUTH_HEADER, DEVICE_ID, instance} from "./root";
// @ts-ignore
import {saveAs} from 'file-saver';

const crypto = require("crypto");

export const userAPI = {
  signIn(msisdn: string) {
    return instance.post("/v2/re-verify", {
      'phone': `+${msisdn}`,
      'method': "callback"
    })
  },
  verify(msisdn: string, code: string) {
    const params = new URLSearchParams();
    const deviceID = localStorage.getItem(DEVICE_ID);
    params.append('phone', `+${msisdn}`);
    params.append('code', code);
    params.append('device[device_id]', String(deviceID))
    return instance.post("/verify", params)
  },
  register(phone: string, recaptcha_token: string) {
    const params = new URLSearchParams();
    let deviceID = localStorage.getItem(DEVICE_ID);
    if (deviceID === null || deviceID === undefined) {
      const newId = crypto.randomBytes(16).toString("hex");
      localStorage.setItem(DEVICE_ID, newId);
      deviceID = newId
    }
    params.append('device[platform]', 'web');
    params.append('device[device_id]', String(deviceID))
    params.append('phone', phone);
    params.append('verify_by', 'sms');
    params.append('recaptcha_response', recaptcha_token)
    
    const query = new URLSearchParams(window.location.search);
    if (query.has('welcome-offer')) {
      params.append('params[welcome-offer]', String(query.get('welcome-offer')));
    }
    
    return instance.post('/register', params)
  },
  getUser(token?: string) {
    const headers: any = {};
    if (token) {
      headers[AUTH_HEADER] = token
    }
    return instance({
      method: 'get',
      headers: {
        ...headers
      },
      url: '/v2/profile'
    })
  },
  isAuthorized() {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
  },
  updateBalance() {
    return instance.get("/user?porta=1")
  },
  getHistory(limit: number, timeoffset?: number) {
    const offset = timeoffset !== undefined && timeoffset > 0 ? "&time_offset=" + timeoffset : "";
    return instance.get(`/v2/history?limit=${limit}${offset}`)
  },
  getPaymentGateways() {
    return instance.get("/v2/products")
  },
  sendDataToMail() {
    return instance.post('/v2/account/personal_data')
  },
  deleteReport() {
    return instance.delete('/v2/account/personal_data')
  },
  sendPromoCode(code: string) {
    return instance.post("/v2/promo", {
      code
    })
  },
  async getDownloadReport() {
    await instance({
      method: 'get',
      url: '/v2/account/download_personal_data',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        const type = res.headers['content-type']
        return new Blob([res.data], {type: type});
      })
      .then((blob) => saveAs(blob, 'yolla_personal_data.zip'))
  },
  async getDownloadReceipt(receiptId: string) {
    await instance({
      method: 'get',
      url: `/v2/account/receipt/${receiptId}`,
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        const type = res.headers['content-type']
        return new Blob([res.data], {type: type});
      })
      .then((blob) => saveAs(blob, `receipt_${receiptId}.pdf`))
  },
  deleteAccount(number: string) {
    return instance.put('/v2/account/delete', {
      phone: number
    })
  },
  deleteAccountConfirm(phone: string, code: string) {
    return instance.put("/v2/account/confirm_delete", {
      phone, code
    })
  },
  setAutotopupEnabled(tokenId: string, enabled: boolean) {
    if (enabled) {
      return instance.put("/v2/auto-topup", {
        token_id: tokenId
      })
    } else {
      return instance.delete(`/v2/auto-topup/${tokenId}`)
    }
  },
  signByToken(token: string) {
    return instance.post('/v2/confirm', {token})
  },
  installSource(params: any) {
    return instance.put('/v2/install_source', {
      utm_params: {
        ...params
      }
    })
  },
  logout() {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  },
  getSubscriptions() {
    return instance.get('/v2/subscriptions')
  },
  activateSubscription(id: string) {
    return instance.post(`/v2/subscriptions/${id}`)
  },
  changeStatusSubscription(status: boolean, id: string) {
    return instance.put(`/v2/subscriptions/${id}`, new URLSearchParams({
      autoRenew: status ? '1' : '0'
    }))
  }
}
