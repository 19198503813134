import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField
} from "@material-ui/core";

import {useStores} from "../../../store/useStore";
import {TUser} from "../../../store/types";
import strings from "../../../util/i18n/strings";

import s from './AccountSettings.module.scss';

export type State = {
  eMail: string,
  subscribedToAccountUpdatesEmail: boolean,
  subscribedToPromotionalEmail: boolean,
  subscribedToPromotionalSms: boolean,
  subscribedToPromotionalPush: boolean
}

const AccountSettingsModal: FC = observer(() => {

  const {
    userStore: {
      user,
      changeAccountSettings
    },
    modalsStore: {
      changeStateModal,
      changeStateSnackbar
    }
  } = useStores();

  const [userSettings, setUserSettings] = useState<State>({
    eMail: '',
    subscribedToAccountUpdatesEmail: false,
    subscribedToPromotionalEmail: false,
    subscribedToPromotionalSms: false,
    subscribedToPromotionalPush: false
  })

  const updateSettings = (userData: TUser) => {
    const {
      email,
      subscribed_to_account_updates_email,
      subscribed_to_promotional_email,
      subscribed_to_promotional_sms,
      subscribed_to_promotional_push
    } = userData;
    setUserSettings({
      eMail: email,
      subscribedToAccountUpdatesEmail: subscribed_to_account_updates_email,
      subscribedToPromotionalEmail: subscribed_to_promotional_email,
      subscribedToPromotionalSms: subscribed_to_promotional_sms,
      subscribedToPromotionalPush: subscribed_to_promotional_push,
    })
  }

  useEffect(() => {
    if (user !== null) {
      updateSettings(user)
    }
  }, [])

  const changeStateSettings = (field: string, value: boolean | string) => {
    setUserSettings({
      ...userSettings,
      [field]: value
    })
  }

  const handleNotificationSettingsSubmit = () => {
    const {
      eMail,
      subscribedToAccountUpdatesEmail,
      subscribedToPromotionalEmail,
      subscribedToPromotionalSms,
      subscribedToPromotionalPush
    } = userSettings
    changeAccountSettings(eMail, subscribedToAccountUpdatesEmail, subscribedToPromotionalEmail, subscribedToPromotionalPush, subscribedToPromotionalSms)
      .then(() => {
        changeStateModal()
      }).catch((err) => {
      changeStateSnackbar(err)
    })
  }

  return (
    <>
      <div className={s.wrapper}>
        <div style={{maxWidth: '256px'}}>
          <TextField
            variant="filled"
            fullWidth={true}
            label={strings.settings_modal.field_label_email}
            placeholder={strings.settings_modal.field_label_email}
            value={userSettings.eMail}
            onChange={(e) => {
              changeStateSettings('eMail', e.target.value)
            }}
          />
        </div>
        <FormControlLabel
          label={strings.settings_modal.field_label_subscribed_to_account_updates_email}
          control={
            <Checkbox
              checked={userSettings.subscribedToAccountUpdatesEmail}
              onChange={(e) => {
                changeStateSettings('subscribedToAccountUpdatesEmail', e.target.checked)
              }}
            />
          }
        />
        <FormControlLabel
          label={strings.settings_modal.field_label_subscribed_to_promotional_email}
          control={
            <Checkbox
              checked={userSettings.subscribedToPromotionalEmail}
              onChange={(e) => {
                changeStateSettings('subscribedToPromotionalEmail', e.target.checked)
              }}
            />
          }
        />
        <FormControlLabel
          label={strings.settings_modal.field_label_subscribed_to_promotional_sms}
          control={
            <Checkbox
              checked={userSettings.subscribedToPromotionalSms}
              onChange={(e) => {
                changeStateSettings('subscribedToPromotionalSms', e.target.checked)
              }}
            />
          }
        />
        <FormControlLabel
          label={strings.settings_modal.field_label_subscribed_to_promotional_push}
          control={
            <Checkbox
              checked={userSettings.subscribedToPromotionalPush}
              onChange={(e) => {
                changeStateSettings('subscribedToPromotionalPush', e.target.checked)
              }}
            />
          }
        />
      </div>
      <DialogActions>
        <Button onClick={() => changeStateModal()}>
          {strings.buttons_modal.cancel_btn}
        </Button>
        <Button onClick={handleNotificationSettingsSubmit}>
          {strings.buttons_modal.ok_btn}
        </Button>
      </DialogActions>
    </>
  )
})

export default AccountSettingsModal;
