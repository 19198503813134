import React, {FC, useEffect, useLayoutEffect} from "react";
import {
  Switch,
  Redirect,
  Route, useHistory, useLocation,
} from "react-router-dom";
import {observer} from "mobx-react";

import {ROUTES} from "../routes";
import {useStores} from "../../store/useStore";
import {getUrlParameter} from "../utils";
import Login from "../../components/login";
import PayInfo from "../../components/PayInfo";
import ProtectedRoute from "../../components/shared/protectedRoute";
import SimpleDialog from "../../components/shared/simpleDialog/SimpleDialog";
import SimpleSnackbar from "../../components/shared/simpleSnackbar";
import RemitlyPage from "../../components/remitly";
import Payment from "../../components/Payment";
import ProtectedRouter from "../protectedRoute";
import MainPage from "../../components/mainPage";
import Subscription from "../../components/subscription";
import {MODALS} from "../../components/shared/simpleDialog/helper";

const LinkController = observer(() => {
  const {userStore: {stateUser}} = useStores();
  const history = useHistory();
  useLayoutEffect(() => {
    if (!stateUser) {
      history.push(ROUTES.LOGIN)
    }
  }, [stateUser])

  return <MainPage/>
})

const Switcher: FC = observer(() => {

  let historyUser = useHistory();

  const {pathname, search} = useLocation();

  const {
    modalsStore: {
      clearHistory,
      replaceLocationSearch,
      changeStateSnackbar,
      modal
    },
    userStore: {
      installSourceAsync,
      getPersonalData,
      stateUser
    },
    subscriptionStore: {
      getSubscriptionsAsync
    }
  } = useStores()

  useEffect(() => {
    if (clearHistory) {
      const path = (stateUser && pathname === '/login') ? '/' : `${!stateUser ? '/login' : pathname}`;
      historyUser.push({
        pathname: path,
      })
    }
    return () => {
      replaceLocationSearch(false)
    }
  }, [clearHistory, stateUser])

  useLayoutEffect(() => {
    const utm = getUrlParameter('utm_source');
    const clickAction = getUrlParameter('click_action');
    if (utm && stateUser) {
      //need this, because we don't know when params has in string
      const parsingStr = search.replace('?', '').split('&');
      let params: any = {};
      parsingStr.forEach((el) => {
        const param = el.split('=');
        if (param.length === 2) {
          const [key, value] = param;
          params[key] = value
        }
      })
      if(params.download_report) {
        getPersonalData()
      }
      installSourceAsync(params)
        .then(() => {
          replaceLocationSearch(true)
        })
        .catch((err) => {
          changeStateSnackbar(err)
        })
    }
    if (utm) {
      historyUser.push({
        pathname: `/${ROUTES.LOGIN}`,
        search: search
      })
    }
    if (clickAction) {
      const path = stateUser ? `${ROUTES.ROOT}` : `/${ROUTES.LOGIN}`;
      historyUser.push({
        pathname: path,
        search: search
      })
    }
  }, [stateUser])

  useLayoutEffect(() => {
    if (modal.name === MODALS.CARD_SUCCESS || modal.name === MODALS.CARD_ERROR || modal.name === MODALS.CARD_SUCCESS) {
      getSubscriptionsAsync()
    }
  }, [modal])

  useLayoutEffect(() => {
    getSubscriptionsAsync()
  }, [pathname])


  return (
    <>
      <Switch>
        <Route path={`/${ROUTES.LOGIN}`} component={Login}/>
        <Route component={PayInfo} path={`/${ROUTES.PAY}`}/>
        <Route component={RemitlyPage} path={`/${ROUTES.REMITLY}`}/>
        <ProtectedRouter path={`${ROUTES.ROOT}${ROUTES.PAYMENT}`} component={Payment}/>
        <ProtectedRouter path={`${ROUTES.ROOT}${ROUTES.SUBSCRIPTION}`} component={Subscription}/>
        <ProtectedRouter path="/" exact component={ProtectedRoute}/>
        <ProtectedRouter path="/no" exact component={ProtectedRoute}/>
        <ProtectedRouter path="/unsubscribe" exact component={ProtectedRoute}/>
        <ProtectedRouter path="/delete" exact component={ProtectedRoute}/>
        <Route path="/link" exact component={LinkController}/>
        <Redirect from="*" to="/"/>
      </Switch>
      <SimpleDialog/>
      <SimpleSnackbar/>
    </>
  )
})

export default Switcher;
