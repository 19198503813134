import React, {FC, useEffect, useState} from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  TextField
} from "@material-ui/core";
import {observer} from "mobx-react";

import {useStores} from "../../../store/useStore";
import {userAPI} from "../../../api";
import strings from "../../../util/i18n/strings";

type Props = {
  args?: any,
}

const PromoCodeModal: FC<Props> = observer(({args}) => {

  const {modalsStore: {changeStateModal}} = useStores()

  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (args?.code) {
      setCode(args.code)
    }
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError('')
    }
    setCode(e.target.value)
  };

  const handleKeyPress = (target: React.KeyboardEvent) => {
    if (target.charCode === 13) {
      handleEnter();
    }
  };

  const handleEnter = () => {
    if (code !== undefined) {
      userAPI.sendPromoCode(code)
        .then(({data}) => {
          changeStateModal();
          const {message} = data.data;
          changeStateModal(strings.promo_modal.apply_code, true, true, 'successCode', {message})
        }).catch((err) => {
        setError(err);
      })
    }
  };

  const handleCloseDialog = () => {
    changeStateModal();
  }

  return (
    <>
      <TextField
        inputProps={{
          maxLength: 20
        }}
        value={code}
        onChange={handleChange}
        helperText={error ? error : null}
        error={!!error}
        style={{width: '100%', maxWidth: 250}}
        onKeyPress={handleKeyPress}
        label={strings.promo_modal.field_label_promo_code}
      />
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {strings.buttons_modal.cancel_btn}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={code != null && code.length === 0}
          onClick={handleEnter}
        >
          {strings.promo_modal.apply_btn}
        </Button>
      </DialogActions>
    </>
  )
});

export default PromoCodeModal;

