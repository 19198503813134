import {makeAutoObservable, runInAction} from "mobx";
import {
  TDirection,
  TListDirection,
  TListPackages,
  TPackages,
  TFields,
  TCalculator,
  TFieldsKeys, TCalculatorData,
} from "./types";
import {remitlyAPI} from "../api/remitlyAPI";
import {REMITLY_DEF_AMOUNT, REMITLY_FROM_COUNTRY, REMITLY_TO_COUNTRY} from "../api/root";

const defaultFields = {
  fromCountry: localStorage.getItem(REMITLY_FROM_COUNTRY) || '',
  toCountry: localStorage.getItem(REMITLY_TO_COUNTRY) || '',
  fromCurrency: "",
  toCurrency: "",
  amount: localStorage.getItem(REMITLY_DEF_AMOUNT) || '100',
  receiveAmount: '',
  package: "EXPRESS",
  email: "",
  firstName: "",
  lastName: "",
  birthDate: ""
}

const defaultPackages = {
  status: true,
  list: [],
  arrayFromTo: [],
  types: [],
  selectedCurrency: '',
  currency: [],
}

export class RemitlyStore {
  fields: TFields = defaultFields;
  sources: TDirection = {
    status: false,
    list: []
  };
  targets: TDirection = {
    status: false,
    list: []
  };
  packages: TPackages = defaultPackages;
  calculator: TCalculator = {
    status: 'none',
  }
  error: string = '';
  isLoadingCalc: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  getFieldValue = (field: TFieldsKeys) => {
    return this.fields[field]
  }

  setFieldValues = (fields: Partial<TFields>) => {
    this.fields = {
      ...this.fields,
      ...fields
    }
  }

  setCalcLoading = (status: boolean) => {
    this.isLoadingCalc = status;
  }

  errorHandler = (err: string) => {
    this.error = err;
    this.packages = defaultPackages;
  }

  setSources = (sources: TListDirection[]) => {
    this.sources = {
      status: false,
      list: sources
    }
  }

  setTargets = (targets: TListDirection[]) => {
    this.targets = {
      status: false,
      list: targets
    }
  }

  amountClear = () => {
    this.fields = {
      ...this.fields,
      amount: this.fields.amount,
      toCurrency: '',
      fromCurrency: '',
    }
  }

  clearCalcData = () => {
    this.calculator = {
      status: 'none'
    }
    this.fields = {
      ...this.fields,
    }
  }

  setCalcData = (calcData: TCalculatorData) => {
    this.calculator = {
      status: 'has',
      data: calcData
    }
    this.fields = {
      ...this.fields,
      amount: String(calcData.sendAmount),
      receiveAmount: String(calcData.receiveAmount)
    }
    this.isLoadingCalc = false;
  }

  setPackages = (packages: TListPackages[]) => {
    const arrayPackages = packages.map((el) => el.package);
    const arrayCurrency = packages.map((el) => el.toCurrency);
    const arrayFromTo = packages.map((el) => {
      return {
        from: el.fromCurrency,
        to: el.toCurrency
      }
    })
    // spread ts not supported, because used Array.from
    //next row create array unique values (note)
    const types = Array.from(new Set(arrayPackages))
    const currency = Array.from(new Set(arrayCurrency))
    let selectedCurrency = '';
    if (currency.length > 0) {
      const [curr] = currency;
      const from = packages.find((el) => el.toCurrency === curr);
      selectedCurrency = curr;
      this.fields = {
        ...this.fields,
        toCurrency: curr,
        fromCurrency: String(from?.fromCurrency)
      }
    }
    this.error = '';
    this.packages = {
      status: false,
      list: packages,
      selectedCurrency: selectedCurrency,
      arrayFromTo: arrayFromTo,
      types: types,
      currency: currency
    }
  }

  getCountryAsync = () => {
    if (this.fields.fromCountry === '') {
      this.sources = {
        ...this.targets,
        status: true
      }
    }
    remitlyAPI.getSources()
      .then((res) => {
        runInAction(() => {
          const {data} = res;
          this.setSources(data)
        })
      })
  }

  getTargetsAsync = (clearable: boolean) => {
    this.targets = {
      ...this.targets,
      status: true
    }
    //needed clear field, because value saved (note);
    if (clearable) {
      this.fields.toCountry = '';
    }
    remitlyAPI.getTargets(this.fields.fromCountry)
      .then((res) => {
        runInAction(() => {
          const {data} = res;
          this.setTargets(data)
        })
      }).catch(() => {
      runInAction(() => {
        this.setTargets([])
      })
    })
  }

  getPackagesAsync = () => {
    this.packages = {
      ...this.packages,
      status: true
    }
    remitlyAPI.getPackages(this.fields.fromCountry, this.fields.toCountry)
      .then((res) => {
        runInAction(() => {
          const {data} = res;
          this.setPackages(data)
          this.getCalculationsAsync()
        })
      }).catch((err) => {
      runInAction(() => {
        this.errorHandler(err)
      })
    })
  }

  getCalculationsAsync = (fieldEdit?: string) => {
    this.calculator = {
      ...this.calculator,
      status: 'loading'
    }
    let param: any = {
      fromCountry: this.fields.fromCountry,
      fromCurrency: this.fields.fromCurrency,
      toCountry: this.fields.toCountry,
      toCurrency: this.fields.toCurrency,
      //need added first start func (because request failed). we set start value amount = 100;
      amount: Number(this.fields.amount),
      package: this.fields.package
    }
    if (fieldEdit === 'receiveAmount') {
      param.receiveAmount = Number(this.fields.receiveAmount)
      delete param['amount']
    }
    if (fieldEdit === 'amount') {
      param.amount = Number(this.fields.amount)
      delete param['receiveAmount']
    }
    this.isLoadingCalc = true;
    if (param?.amount === 0) {
      this.calculator = {
        status: 'none'
      }
    }
    remitlyAPI.getCalculations(param)
      .then((res) => {
        const {data} = res;
        runInAction(() => {
          this.setCalcData(data);
          this.setCalcLoading(false);
        })
      }).catch(() => {
      runInAction(() => {
        this.setCalcLoading(false);
      })
    })
  }

  sendDataAsync = () => {
    const fields = {
      ...this.fields,
      amount: Number(this.fields.amount),
    }
    let param: any = {}
    Object.entries(fields).forEach(([key, value]) => {
      if (value) {
        param[key] = value
      }
    })
    return remitlyAPI.getCalculations(param)
  }

  postRemitlyDataAsync = (url: string) => {
    const fields = {
      firstName: this.fields.firstName,
      lastName: this.fields.lastName,
      email: this.fields.email,
      birthDate: this.fields.birthDate,
      url: url,
    }
    return remitlyAPI.postRemitlyData(fields)
  }
}
