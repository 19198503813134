import {createContext} from "react";
import {HistoryStore} from "./HistoryStore";
import {UserStore} from "./UserStore";
import {ModalsStore} from "./ModalsStore";
import {PaymentsStore} from "./PaymentsStore";
import {RemitlyStore} from "./RemitlyStore";
import {SubscriptionStore} from "./SubscriptionStore";

export const rootStoreContext = createContext({
  historyStore: new HistoryStore(),
  userStore: new UserStore(),
  modalsStore: new ModalsStore(),
  paymentsStore: new PaymentsStore(),
  remitlyStore: new RemitlyStore(),
  subscriptionStore: new SubscriptionStore()
});
