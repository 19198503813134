import {instance} from "./root";
import {TRequestNotificationSettings} from "./types";

export const settingsAPI = {
  updateNotificationSettings(params: TRequestNotificationSettings) {
    const bodyFormData = new FormData();
    Object.entries(params).map(([key, value]) => {
      if (key === 'email') {
        bodyFormData.append(key, String(value));
      } else {
        bodyFormData.append(key, value ? '1' : '0')
      }
    })
    return instance({
      headers: {"Content-Type": "multipart/form-data"},
      method: 'post',
      url: '/user',
      data: bodyFormData,
    })
  }
}
