import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useHistory, useLocation} from "react-router-dom";
import {Icon, TextField} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from "axios";
import * as yup from 'yup';

import {ROUTES} from "../../../util/routes";
import {
  socialLinks,
  informationColumn,
  helpColumn,
  downloadLinks,
  privacyInformation
} from "./helper";
import QRCode from "../../../img/QRcode.svg";
import strings from "../../../util/i18n/strings";
import {YOLLA_LANG} from "../../../api/root";
import LanguageIcon from "../../../img/earth.svg";
import {languages} from "../../../util/i18n/lang";
import {useStores} from "../../../store/useStore";

import s from './footer.module.scss';
import {postDOMForm} from "../../../util/utils";

type Props = {
  siteUrl: string
}

const getStyleMarket = (el: string) => {
  switch (el) {
    case "googleplay":
      return `${s.market} ${s.googleplay}`;
    case "appstore":
      return `${s.market} ${s.appstore}`
  }
}

const getStyleSocial = (el: string) => {
  switch (el) {
    case "twitter":
      return `${s.twitter} ${s.pad}`;
    case "facebook":
      return `${s.facebook}`;
    case "youtube":
      return `${s.youtube} ${s.pad}`;
    case "instagram":
      return `${s.instagram} ${s.pad}`;
  }
}

const Footer: FC<Props> = observer(({siteUrl}) => {
  const {pathname} = useLocation();
  let history = useHistory()

  const [email, setEmail] = useState<string>('');
  const [initialLang, setInitialLang] = useState<string>('en');
  const [lang, setLang] = useState<boolean>(false);


  const {modalsStore: {
    changeStateSnackbar
  }} = useStores();

  useEffect(() => {
    const lang = localStorage.getItem(YOLLA_LANG);
    if (lang) {
      setInitialLang(lang)
    }
  }, [])

  const changeLocale = (lang: string) => {
    localStorage.setItem(YOLLA_LANG, lang);
    setInitialLang(lang);

    let url = new URL(window.location.href);

    if (url.searchParams.has('lang')) {
      url.searchParams.set('lang', lang);
      window.location.href = url.toString();
    } else {
      history.go(0);
    }
  }
  const subscribe = (e: any) => {
    e.preventDefault();
    if(email) {
      const url = 'https://email.yollacalls.com/add_subscriber.html'
      postDOMForm(url, {
        email: email,
        campaign_token: 9,
        thankyou_url: 'https://yollacalls.com'
      }, 'e')
      setEmail('');
    } else {
      changeStateSnackbar(strings.footer.email_is_not_specified)
    }
  }

  if (pathname.includes(ROUTES.REMITLY)) {
    return null
  }

  const arrayLang = languages.filter(el => el !== initialLang)

  return (
    <div className={s.footer}>
      <div className={s.wrapperDesktop}>
        <div className={s.spaceAround}>
          <div className={`${s.item} ${s.hide}`}>
            <div className={s.info}>
              <div className={s.title}>
                {strings.footer.information}
              </div>
              <ul className={s.ul}>
                {informationColumn.map((el) => (
                  <li key={`informationColumn${el.name}`}>
                    <a href={`${siteUrl}${el.link}`} className={s.a}>{el.name}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={s.help}>
              <div className={s.title}>
                {strings.footer.help}
              </div>
              <ul className={s.ul}>
                {helpColumn.map((el) => (
                  <li key={`helpColumn${el.name}`}>
                    <a href={`${siteUrl}${el.link}`} className={s.a}>{el.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={`${s.item} ${s.lowItem}`}>
            {/*<div className={s.subscribe}>*/}
            {/*  <div className={s.title}>*/}
            {/*    {strings.footer.subscribe_text}*/}
            {/*  </div>*/}
            {/*  <form onSubmit={subscribe}>*/}
            {/*    <div className={s.wrapInput}>*/}
            {/*      <TextField*/}
            {/*        variant="outlined"*/}
            {/*        className={s.input}*/}
            {/*        value={email}*/}
            {/*        placeholder={strings.footer.field_label_email}*/}
            {/*        onChange={(e) => setEmail(e.target.value)}*/}
            {/*      />*/}
            {/*      <button className={s.arrow} type="submit">*/}
            {/*        <ArrowForwardIosIcon/>*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*  </form>*/}
            {/*  <div className={s.stub}>*/}
            {/*    {strings.footer.by_subscribing_you_agree_to}*/}
            {/*    <a href={`${siteUrl}/${privacyInformation[0].link}`} className={s.a}>*/}
            {/*      {` ${strings.footer.privacy_policy}`}*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={s.download}>
              <div className={s.title}>
                {strings.footer.download_app_and_get_bonus}
              </div>
              <div className={s.wrap}>
                <div className={s.store}>
                  {downloadLinks.map((el, index) => (
                    <a href={`${el.link}`} className={`${getStyleMarket(el.style)}`}
                       key={`download-links-${index}`}/>
                  ))}
                </div>
                <div className={s.qrcode}>
                  <img src={QRCode} alt="QRCode"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={s.bottom}>
          <div className={s.breadcrumbs}>
            <a href='https://yollacalls.com/' className={s.active}>Yolla</a>
            <span><ArrowForwardIosIcon/></span>
            <a>{strings.footer.account}</a>
          </div>
          <div className={s.social}>
            {socialLinks.map((social, index) => (
              <a href={social.link} key={`social-mobile-${index}`}>
                <div className={`${s.icon} ${getStyleSocial(social.name)}`}/>
              </a>
            ))}
          </div>
          <div className={s.lang}>
            <div
              className={s.pointer}
              onMouseEnter={() => setLang(true)}
              onMouseLeave={() => setLang(false)}
            >
              <a>
                {initialLang}
              </a>
              <span className={s.caret}>
                   <Icon className={s.iconLang}>
                    <img src={LanguageIcon} alt=''/>
                  </Icon>
                </span>
              {lang && (
                <ul className={`${s.ul} ${s.dropdownMenu} ${s.langDrop}`}>
                  {arrayLang.map((el, index) => (
                    <li key={index}>
                      <a onClick={() => {
                        changeLocale(el);
                      }} className={s.a}>
                        {el}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className={s.footerCopyright}>
            <div className={s.copyright}>
              © 2015 - {new Date().getFullYear()} Yolla Calls International OÜ
            </div>
            <div className={s.tosLinks}>
              {privacyInformation.map((el, index) => (
                <a href={`${siteUrl}/${el.link}`} key={`privacy-information-${index}`}>{el.name}</a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});

export default Footer;
