import React, {FC, useEffect} from "react";
import {Button, TextField} from "@material-ui/core";
import {useFormik} from "formik";
import {observer} from "mobx-react";
import * as yup from "yup";
import {useHistory} from "react-router-dom";
import LockIcon from '@material-ui/icons/Lock';

import {useStores} from "../../../../store/useStore";
import {ROUTES} from "../../../../util/routes";
import strings from "../../../../util/i18n/strings";

import s from "../../RemitlyPage.module.scss";

const validationSchema = yup.object({
  email: yup.string().required('Field required'),
})

const EmailScreen: FC = observer(() => {

  let history = useHistory();

  const {
    remitlyStore: {
      getFieldValue,
      setFieldValues
    },
    userStore: {
      user
    }
  } = useStores();

  useEffect(() => {
    if (getFieldValue('fromCountry') === '') {
      history.push(`/${ROUTES.REMITLY}/${ROUTES.DIRECTION}`)
    }
  }, [])

  useEffect(() => {
    if(getFieldValue('fromCurrency') === '') {
      history.push(`/${ROUTES.REMITLY}/${ROUTES.AMOUNT}`)
    }
  })

  const formik = useFormik({
    initialValues: {
      email: getFieldValue('email'),
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values => {
      setFieldValues({email: String(values.email)});
      history.push(`${ROUTES.ROOT}${ROUTES.REMITLY}/${ROUTES.PROFILE.ROOT}/${ROUTES.PROFILE.NAME}`)
    })
  })

  const {handleSubmit, handleChange, values, isValid, resetForm} = formik;

  useEffect(() => {
    if (user?.email && getFieldValue('email') === '') {
      changeRestForm({email: user.email})
    }
  }, [])

  const changeRestForm = (changeValues: any, changeErrors: any = {}) => {
    resetForm({
      values: {...changeValues},
      errors: {...changeErrors}
    })
  }

  const getValid = () => {
    const arrayValid = Object.entries(values).filter(([key, value]) => value !== '');
    return arrayValid.length === 0;
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  })

  return (
    <div className={s.wrapper}>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={s.title}>
          {strings.email_screen.title}
        </div>
        <div className={s.row}>
          <TextField
            fullWidth={true}
            onChange={handleChange}
            name="email"
            type="email"
            variant="outlined"
            InputProps={{
              type: 'email'
            }}
            className={s.customField}
            value={values.email}
            label={strings.email_screen.field_label_email}
            placeholder={strings.email_screen.field_label_email}
          />
        </div>
        <div className={s.row}>
          <Button
            type="submit"
            className={s.submit}
            variant="contained"
            color="primary"
            disableRipple={true}
            disabled={!isValid || getValid()}
          >
            {strings.buttons_modal.continue_btn}
          </Button>
        </div>
        <div className={s.info}>
          <LockIcon/>
          <div>
            {strings.protected_info}
          </div>
        </div>
      </form>
    </div>
  )
})

export default EmailScreen;
