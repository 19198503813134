import {makeAutoObservable, runInAction} from "mobx";
// @ts-ignore
import {saveAs} from 'file-saver';
import {TUser} from "./types";
import {settingsAPI, userAPI} from "../api";
import {ACCESS_TOKEN_KEY, ERROR_NUMBER_VALID, instance, LOCALSTORAGE_RECURRING_GATEWAY} from "../api/root";
import strings from "../util/i18n/strings";

export class UserStore {
  user: TUser | null = null;
  userStatus: string = '';
  loading: boolean = true;
  phone: string = '';
  code: string | null = null;
  error: any | null = null;
  loginProgress: boolean = false;
  recurringToken: undefined | string = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get stateUser() {
    return this.user !== null;
  }

  clearData = () => {
    this.user = null;
    this.loading = false;
    this.phone = '';
    this.code = null;
    this.error = null;
    this.loginProgress = false;
  }

  setDataUser(user: TUser | null) {
    this.user = user;
    this.loading = false;
  }

  setPhone(phone: string) {
    this.phone = phone;
    this.error = null;
    this.loginProgress = false;
  }

  setError(err: any) {
    this.error = err ? err : '';
    this.loginProgress = false;
  }

  setVerify(code: string, status: string) {
    this.code = code;
    this.userStatus = status;
    this.loginProgress = false;
    this.error = null;
    this.getUserAsync()
  }

  changeAccountSettings = async (email: string, accountUpdates: boolean, promoUpdatesEmail: boolean, promoUpdatesPush: boolean, promoUpdatesSms: boolean) => {
    await settingsAPI.updateNotificationSettings({
      email,
      subscribed_to_account_updates_email: accountUpdates,
      subscribed_to_promotional_email: promoUpdatesEmail,
      subscribed_to_promotional_push: promoUpdatesPush,
      subscribed_to_promotional_sms: promoUpdatesSms
    })
      .then(() => {
        runInAction(() => {
          this.getUserAsync();
        })
      })
  }

  signByToken = async (token: string) => {
    this.loading = true;
    await userAPI.signByToken(token)
      .then(({data}) => {
        const {data: accessData} = data;
        const token = accessData.token ? accessData.token : accessData.access_token;
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        runInAction(() => {
          this.getUserAsync(true, token);
        })
      }).catch((err) => {
        throw new Error(err);
      })
  }

  getPersonalData = () => {
    instance({
      method: 'get',
      url: '/v2/account/personal_data',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const type = res.headers['content-type']
      return new Blob([res.data], {type: type});
    }).then((blob) => {
      saveAs(blob, 'yolla_personal_data.zip');
    })
  }

  signInAsync = async (phone: string) => {
    this.loginProgress = true;
    await userAPI.signIn(phone)
      .then(() => {
        runInAction(() => {
          this.setPhone(phone)
        })
      }).catch((err: any) => {
        this.setError(err)
      })
  }

  verifyCodeAsync = async (phone: string, code: string) => {
    this.loginProgress = true;
    await userAPI.verify(phone, code)
      .then((res) => {
        const {data} = res;
        if (data?.error?.messages) {
          runInAction(() => {
            this.setError(data.error.messages.error)
          })
        } else {
          const token = res.headers['x-yolla-auth-token'];
          const {data: {user}} = res.data;
          let status: string = '';
          const userSeconds = user.created_at;
          const seconds = Math.floor(Date.now() / 1000) - 3600;
          if (seconds <= userSeconds) {
            status = 'new'
          }
          localStorage.setItem(ACCESS_TOKEN_KEY, token)
          runInAction(() => {
            this.setVerify(code, status)
          })
        }
      }).catch((err: any) => {
        this.setError(err)
      })
  }

  registerAsync = async (phone: string, recaptcha_token: string) => {
    this.loginProgress = true;
    await userAPI.register(phone, recaptcha_token)
      .then((res) => {
        const {data} = res;
        runInAction(() => {
          if(data?.error?.code === 1001 || data?.error?.code === 1100) {
            this.setError(ERROR_NUMBER_VALID);
            return;
          }
          if (data?.error?.messages) {
            const error = strings.login.error_auth
            this.setError(error)
          } else {
            this.setPhone(phone)
          }
        })
      }).catch((err: any) => {
        this.setError(err)
      })
  }

  getUserAsync = async (condition?: boolean, token?: string) => {
    await userAPI.getUser(token)
      .then((res) => {
        const {data} = res.data;
        runInAction(() => {
          this.setDataUser(data);
          if (condition) {
            window.location.search = '';
          }
        })
      }).catch(() => {
        runInAction(() => {
          this.clearData()
        })
      })
  }

  setAutotopupEnabled = async (enabled: boolean) => {
    await userAPI.getPaymentGateways()
      .then((res) => {
        const {data} = res.data;
        for (let i in data.gateways) {
          let tokens = data.gateways[i].recurring_data;
          if (tokens && tokens.length > 0) {
            userAPI.setAutotopupEnabled(tokens[0].id, enabled);
          }
        }
      })
  }

  deleteRecurring = async () => {
    localStorage.removeItem(LOCALSTORAGE_RECURRING_GATEWAY);
  }

  getRecurringDefaultToken = async () => {
    await userAPI.getPaymentGateways()
      .then((res) => {
        const {data} = res.data;
        for (let i in data.gateways) {
          let tokens = data.gateways[i].recurring_data;
          if (tokens && tokens.length > 0) {
            this.recurringToken = tokens[0];
          }
        }
      })
  }

  sendConfirmAndDeleteAccount = async (number: string, code: string) => {
    await userAPI.deleteAccountConfirm(number, code)
      .then(() => {
        runInAction(() => {
          this.logoutAsync()
        })
      })
  }

  sendDataToEmail = async () => {
    await userAPI.sendDataToMail()
      .then(() => {
        runInAction(() => {
          this.getUserAsync()
        })
      })
  }

  deleteAccountConfirm = async (number: string) => {
    return userAPI.deleteAccount(number)
  }

  openReportLink = async () => {
    await userAPI.getDownloadReport();
  }

  deleteReport = async () => {
    await userAPI.deleteReport()
      .then(() => {
        runInAction(() => {
          this.getUserAsync()
        })
      })
  }

  getAuthAsync = async () => {
    this.loading = true;
    const token = await userAPI.isAuthorized()
    if (token) {
      runInAction(() => {
        this.getUserAsync();
        this.getRecurringDefaultToken()
      })
    } else {
      this.loading = false;
    }
  }

  updateBalanceAsync = () => {
    userAPI.updateBalance()
      .then(() => {
        runInAction(() => {
          this.getUserAsync();
        })
      }).catch(() => {
      runInAction(() => {
        this.getUserAsync();
      })
    })
  }

  installSourceAsync = (params: any) => {
    return userAPI.installSource(params)
  }

  logoutAsync = async () => {
    this.loading = false;
    await userAPI.logout()
    runInAction(() => {
      this.clearData()
    })
  }


}
