import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {BrowserRouter as Router, useHistory, useLocation} from "react-router-dom";
import {IntercomProvider} from 'react-use-intercom';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {observer} from "mobx-react";

import {UseHookStore} from "../util/hookStore";
import {useStores} from "../store/useStore";
import {ROUTES} from "../util/routes";
import {CONSTANTS} from "../util/utils";

import theme from "./../components/theme";
import HeaderContainer from "./shared/header";
import Footer from "./shared/footer";
import Loader from "./shared/loader";
import Switcher from "../util/switcher";

import s from './App.module.scss';

type Props = {
  siteUrl: string
}

const colors = ['#ffffff', '#e2ffff']

type RootProps = {
  siteUrl: string,
  stateUser: boolean
}

const Root: FC<RootProps> = ({siteUrl, stateUser}) => {
  const {pathname, search} = useLocation();
  const history = useHistory();
  const [clx, setClx] = useState<string>(s.main);


  const getClx = () => {
    const [white, blue] = colors;
    if (pathname.includes(ROUTES.REMITLY)) {
      return white
    } else {
      return blue
    }
  }

  useLayoutEffect(() => {
    const isRemitly = pathname.includes('remitly');
    const isSubscription = new URLSearchParams(search);
    if (!stateUser && isRemitly) {
      history.push({
        pathname: `/${ROUTES.LOGIN}`,
        state: ROUTES.REMITLY,
        search: search
      })
    }
    if (!stateUser && isSubscription.get(CONSTANTS.WELCOME_OFFER)) {
      history.push({
        pathname: `/${ROUTES.LOGIN}`,
        state: ROUTES.SUBSCRIPTION,
        search: search
      })
    }
  }, [])

  useEffect(() => {
    if (pathname.includes(ROUTES.REMITLY)) {
      setClx(`${s.main} ${s.mainFix}`)
      return;
    }
    if (pathname.includes(ROUTES.LOGIN)) {
      setClx(`${s.main} ${s.mainLogin}`)
      return;
    }
    if (pathname.includes(ROUTES.PAYMENT)) {
      setClx(`${s.main} ${s.mainLogin}`);
      return;
    }
    if (pathname.includes(ROUTES.SUBSCRIPTION)) {
      setClx(`${s.main} ${s.mainLogin}`);
      return;
    }
    if (pathname.includes(ROUTES.PAY)) {
      setClx(`${s.main} ${s.mainPay}`)
      return;
    } else {
      setClx(s.main)
    }
  }, [pathname])

  return (
    <div className={s.root} style={{backgroundColor: getClx()}}>
      <HeaderContainer siteUrl={siteUrl}/>
      <div className={clx}>
        <UseHookStore/>
        <Switcher/>
      </div>
      <Footer siteUrl={siteUrl}/>
    </div>
  )
};

const App: FC<Props> = observer(({siteUrl}) => {

  const {hostname} = window.location;

  const getIntercomId = () => {
    if(hostname === "my-test.yollacalls.com") {
      return "xux1g9hh"
    }
    return process.env.REACT_APP_INTERCOM_ID || "";
  }

  const {userStore: {loading, getUserAsync, stateUser}} = useStores();

  useEffect(() => {
    getUserAsync();
  }, [])

  if (loading) {
    return <Loader/>
  }

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <IntercomProvider appId={getIntercomId()}>
          <Root siteUrl={siteUrl} stateUser={stateUser}/>
        </IntercomProvider>
      </MuiThemeProvider>
    </Router>
  )
})

export default App;
