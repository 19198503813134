import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Button, SvgIcon, CircularProgress} from "@material-ui/core";

import {useStores} from "../../store/useStore";
import {TCredits, TTypesCredits} from "../../store/types";
import {postDOMForm} from "../../util/utils";
import strings from "../../util/i18n/strings";

import s from './PaymentSettings.module.scss';

const getClassName = (name: string) => {
  switch (name) {
    case 'ecommpay':
      return s.cardIcon;
    case 'paypal':
      return s.paypal;
    default:
      return s.cardIcon;
  }
}

const PaymentSettings: FC = observer(() => {

  const [status, setStatus] = useState<boolean>(false);
  const [selectAmount, setSelectAmount] = useState<boolean>(false);
  const [types, setTypes] = useState<TTypesCredits[] | []>([]);
  const [process, setProcess] = useState<boolean>(false);

  const handleClickButton = () => {
    setStatus(!status);
    setSelectAmount(false);
    setTypes([]);
  }

  const {
    paymentsStore: {
      getCreditsAsync,
      credits,
      getGatewaysAsync,
      openPayAsync
    },
    userStore: {
      user,
      updateBalanceAsync,
    },
    modalsStore: {
      changeStateModal,
      changeStateSnackbar
    }
  } = useStores()

  useEffect(() => {
    getCreditsAsync()
  }, [])

  const chooseAmount = async (id: string) => {
    setProcess(true);
    getGatewaysAsync(id)
      .then((res) => {
        setTypes(res);
        setProcess(false);
      }).catch(() => {
      setTypes([])
      setProcess(false);
    })
  }

  const openPay = (id: string, name: string) => {
    setProcess(true);
    openPayAsync(id, name)
      .then((res) => {
        const {method, params, url} = res;
        if (method.toUpperCase() === "POST") {
          postDOMForm(url, params)
        } else {
          const link = document.createElement('a');
          link.href = url;
          link.click();
        }
        setProcess(false);
      }).catch((err) => {
      setProcess(false);
      changeStateSnackbar(err)
    })
  }

  const openPromo = () => {
    changeStateModal(strings.promo_modal.title, true, true, 'promo')
  }

  return (
    <div className={s.balance}>
      <div className={s.toolbar}>
        {strings.balance.header}
        <a href="#" onClick={(e): void => {
          e.preventDefault()
          openPromo()
        }}>
          {strings.balance.promo_code_btn}
        </a>
      </div>
      <div className={s.content}>
        <div className={s.status}>
          <h1 onClick={updateBalanceAsync} className={s.updateBalance}>${user?.balance.toFixed(2)}</h1>
          <Button
            onClick={handleClickButton}
            variant={status ? "text" : "contained"}
            color={status ? 'default' : 'secondary'}
            className={s.desktopBtn}
          >
            {status ? strings.balance.cancel_btn : strings.balance.add_credit_btn}
          </Button>
        </div>
        <div className={s.footer}>
          <div className={s.info}>
            {status ? (
              <>
                {process ?
                  <div className={s.processLoader}>
                    <CircularProgress size={30}/>
                  </div>
                  :
                  <>
                    {selectAmount ?
                      <span className={s.chooseAmount}>
                    <label className={s.label}>
                      <a href="https://yollacalls.com/credentials-storage-agreement"
                         className={s.agreement}
                         target="_blank">
                        {strings.balance.agreement_link}
                      </a>
                    </label>
                    <div className={s.types}>
                      {types.map((el: TTypesCredits) => (
                        <Button
                          key={el.name}
                          variant="outlined"
                          onClick={() => openPay(el.productId, el.name)}
                          startIcon={<SvgIcon className={`${s.defaultButton} ${getClassName(el.name)}`}/>}
                        >
                          {el.type}
                        </Button>
                      ))}
                    </div>
                  </span>
                      :
                      <span className={s.chooseAmount}>
              <label className={s.chooseAmountLabel}>
                {strings.balance.choose_text}
              </label>
              <div className={s.customButtons}>
                {credits.length > 0 && credits.map((product: TCredits) => (
                  <span key={product.id}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    chooseAmount(product.id);
                    setSelectAmount(true);
                  }}
                >
                    {product.title}
                </Button>
                    &nbsp;&nbsp;
            </span>
                ))}
              </div>
            </span>}
                  </>
                }
              </>
            ) : (
              <>
                <label>
                  {strings.balance.payments_methods_text}
                </label>
                <div className={s.flexItem}>
                  <div className={s.paypalIcon}/>
                  <div className={s.mastercardIcon}/>
                  <div className={s.visaIcon}/>
                </div>
                <div className={s.clear}/>
              </>
            )}
          </div>
        </div>
        <Button
          onClick={handleClickButton}
          variant={status ? "text" : "contained"}
          color={status ? 'default' : 'secondary'}
          className={s.mobileBtn}
        >
          {status ? strings.balance.cancel_btn : strings.balance.add_credit_btn}
        </Button>
      </div>
    </div>
  )
})

export default PaymentSettings;
