import React, {FC} from "react";
import moment from "moment";
import {Button, Switch} from "@material-ui/core";
import {CircleFlag} from 'react-circle-flags';

import strings from "../../util/i18n/strings";
import {getCountryName} from "../../util/countries";
import {TCards, TSubscription} from "../../store/types";

import s from "./SubscriptionSection.module.scss";

type Props = {
  isMobile: boolean,
  item: TSubscription,
  cards: TCards[],
  link: string,
  changeStatusSubscription: (status: boolean, id: string) => void,
}

const ActiveSubscription: FC<Props> = ({
                                         isMobile,
                                         item,
                                         cards,
                                         changeStatusSubscription,
                                         link
                                       }) => {
  return (
    <div className={s.subscription}>
      <div className={s.toolbar}>
        {strings.subscription.header_active}
        {!isMobile && (
          <a href={link} target="_blank">
            {strings.subscription.header_link}
          </a>
        )}
      </div>
      <div className={s.content}>
        <div className={s.data}>
          <div className={s.flagContainer}>
            <div className={s.flag}>
              <CircleFlag countryCode={item.country.toLowerCase()}/>
            </div>
            {getCountryName(item.country)}
          </div>
          <div className={s.duration}>
            <div className={`${item.remain > 0 ? s.counter : s.counterRed}`}>
              {`${item.remain}/${item.volume} ${strings.subscription.time_format_demo_subscription}`}
            </div>
            <div>
              {`${strings.subscription.expires_on} ${moment.unix(item.validTo).format('MMM DD, YYYY')}`}
            </div>
          </div>
          <div className={s.volume}>
            <div className={s.amount}>{item?.amount}$</div>
          </div>
        </div>
        <div className={s.message}>
          {strings.subscription.active_subscription}
          <div>
            <Switch
              checked={item.autoRenew}
              onClick={() => changeStatusSubscription(!item.autoRenew, item.id)}
              disableRipple={true}
            />
          </div>
        </div>
        <div className={s.textContentSub}>
          {strings.subscription.active_subscription_text.split('\n').map((text, idx) => (
            <p key={idx}>{text}</p>
          ))}
        </div>
        {!item.autoRenew && (
          <div className={s.footerActive}>
            <div className={s.info}>
              <label>
                {strings.balance.payments_methods_text}
              </label>
              <div className={s.flexItem}>
                <div className={s.mastercardIcon}/>
                <div className={s.visaIcon}/>
              </div>
              <div className={s.clear}/>
            </div>
            {!isMobile &&
              <Button
                variant="contained"
                color="secondary"
                className={s.mobileBtn}
                onClick={() => {
                  changeStatusSubscription(!item.autoRenew, item.id)
                }}
              >
                {strings.subscription.btn_text_renew}
              </Button>
            }
          </div>
        )}
        <div className={s.mobileLink}>
          {isMobile && (
            <div className={s.mobileFooter}>
              {!item.autoRenew && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={s.mobileBtn}
                  onClick={() => {
                    changeStatusSubscription(!item.autoRenew, item.id)
                  }}
                >
                  {strings.subscription.btn_text_renew}
                </Button>
              )}
              <a href={link} target="_blank">
                {strings.subscription.header_link}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ActiveSubscription;
