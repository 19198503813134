import React, {FC, useEffect, useState} from "react";
import CloseIcon from '@material-ui/icons/Close';

import strings from "../../../util/i18n/strings";
import {YOLLA_PAYINFO} from "../../../api/root";

import s from "./payment-info.module.scss";

const getColor = (type: string, color: string | undefined) => {
  if (type === 'bg' && color) {
    return {
      backgroundColor: color
    }
  }
  if (type === 'text' && color) {
    return {
      color: color
    }
  }
}

type Props = {
  color?: string,
  colorTxt?: string
}

const PaymentInfo: FC<Props> = ({color, colorTxt}) => {
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    const hidden = localStorage.getItem(YOLLA_PAYINFO)
    if(hidden) {
      setVisible(false)
    }
  }, [])

  const [start, bold, end] = strings.payment.text_end.split('/bold/');

  const closeInfo = () => {
    localStorage.setItem(YOLLA_PAYINFO, 'hidden')
    setVisible(false);
  }

  return (
    <div className={visible ? s.cardPaymentsInfo : `${s.cardPaymentsInfo} ${s.cardInvisible} `} style={getColor('bg', color)}>
      <div className={s.iSign}/>
      <div className={s.cardText} style={getColor('text', colorTxt)}>
        {strings.payment.text_start}<br/>
        {start} <strong>{bold}</strong> {end}
      </div>
      <div className={s.close} onClick={closeInfo}>
      <CloseIcon/>
      </div>
    </div>
  )
}

export default PaymentInfo;
