import {makeAutoObservable, runInAction} from "mobx";
import {AxiosResponse} from "axios";

import {TSubscription, TSubState} from "./types";
import {userAPI} from "../api";

export class SubscriptionStore {
  subscriptions: TSubState = {
    loading: true,
    list: []
  };
  loading: boolean = true;

  constructor() {
    makeAutoObservable(this)
  }

  setSubscriptions = (subscriptions: TSubscription[]) => {
    this.subscriptions = {
      loading: false,
      list: subscriptions
    };
  }

  getSubscriptionsAsync = () => {
    userAPI.getSubscriptions()
      .then((res: AxiosResponse<TSubscription[]>) => {
        const {data} = res;
        runInAction(() => {
          this.setSubscriptions(data)
        })
      }).catch(() => {
      this.setSubscriptions([])
    })
  }

  activateSubscriptionAsync = (id: string) => {
    this.loading = true;
    return userAPI.activateSubscription(id)
  }

  changeStatusSubscriptionAsync = (status: boolean, id: string) => {
    return userAPI.changeStatusSubscription(status, id);
  }
}
