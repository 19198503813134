import React, {FC} from "react";
import moment from "moment";

import {userAPI} from "../../../../api";
import strings from "../../../../util/i18n/strings";

import s from "../../HistorySection.module.scss";

type Props = {
  record: any
}

export const PaymentRecordWithReceipt: FC<Props> = ({record}) => {
  return (
    <tr className={s.topup}>
      <td className={s.bold}>{strings.history.row_content_top_up}</td>
      <td colSpan={2}>{moment(new Date(record.timestamp * 1000)).format("D MMM HH:mm")}</td>
      <td><a style={{cursor: 'pointer'}} onClick={(e) => {
        e.preventDefault()
        userAPI.getDownloadReceipt(record.receipt_id)
      }}>PDF</a></td>
      <td className={s.rate}/>
      <td>+ ${record.amount}</td>
    </tr>
  )
};
