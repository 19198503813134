import React, {FC} from "react";
import {observer} from "mobx-react";
import {
  Button,
  DialogActions,
  DialogContent
} from "@material-ui/core";

import {useStores} from "../../../store/useStore";
import strings from "../../../util/i18n/strings";

type Props = {
  args?: any
}

const AlertModal: FC<Props> = observer(({args}) => {
  const {modalsStore: {changeStateModal}} = useStores();
  return (
    <>
      {args && (
        args.message || ''
      )}
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            changeStateModal()
          }}
        >
          {strings.buttons_modal.ok_btn}
        </Button>
      </DialogActions>
    </>
  )
})

export default AlertModal;
