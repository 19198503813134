import React, {FC} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../../store/useStore";
import {Snackbar} from "@material-ui/core";

const SimpleSnackbar: FC<any> = observer(() => {
  const {modalsStore: {
    snackbar,
    changeStateSnackbar
  }} = useStores();

  return (
    <Snackbar
      open={snackbar.open}
      message={snackbar.message}
      onClose={() => {
        changeStateSnackbar(undefined)
      }}
      autoHideDuration={5000}
    />
  )
})

export default SimpleSnackbar
