import {makeAutoObservable} from "mobx";
import {TModal, TSnackbar} from "./types";

export class ModalsStore {
  snackbar: TSnackbar = {
    open: false,
    message: '',
  }
  modal: TModal = {
    open: false,
    title: '',
    content: false,
    name: null
  }
  clearHistory: boolean = false;

  constructor() {
    makeAutoObservable(this)
  }

  replaceLocationSearch = (arg: boolean = false) => {
    this.clearHistory = arg;
  }

  changeStateSnackbar = (error?: any) => {
    if (error !== undefined) {
      this.snackbar = {
        open: true,
        message: error
      }
    } else {
      this.snackbar = {
        open: false,
        message: ''
      }
    }
  }

  changeStateModal = (title = '', open = false, content = false, name: string | null = null, args?: any) => {
    this.modal = {
      open: open,
      title: title,
      content: content,
      name,
      args
    }
  }
}
