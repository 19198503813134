import React, {FC, useEffect, useState} from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {observer} from "mobx-react";
import {useStores} from "../../../store/useStore";
import {Slide} from "@material-ui/core";
import {TransitionProps} from "@material-ui/core/transitions";

import LogoutModal from "../../modals/logout";
import AccountSettingsModal from "../../modals/accountSettings";
import DownloadDataModal from "../../modals/downloadData";
import DeleteAccountModal from "../../modals/deleteAccount";
import PromoCodeModal from "../../modals/promoCode/PromoCodeModal";
import SuccessfulCardModal from "../../modals/successfulCardModal";
import AlertModal from "../../modals/alertModal";
import {MODALS} from "./helper";
import CancelSubscription from "../../modals/cancelSubscription";


const getChildren = (name: string | null, args?: any) => {
  switch (name) {
    case MODALS.SETTINGS:
      return <AccountSettingsModal/>;
    case MODALS.LOGOUT:
      return <LogoutModal/>;
    case MODALS.DOWNLOAD:
      return <DownloadDataModal/>
    case MODALS.DELETE:
      return <DeleteAccountModal/>
    case MODALS.PROMO:
      return <PromoCodeModal args={args}/>
    case MODALS.CARD_SUCCESS:
      return <SuccessfulCardModal/>
    case MODALS.CARD_ERROR:
    case MODALS.CODE_SUCCESS:
      return <AlertModal args={args}/>;
    case MODALS.CANCEL_SUBSCRIPTION:
      return <CancelSubscription args={args}/>
    default:
      return <div/>
  }
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

type TSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;

const SimpleDialog: FC = observer(() => {
  const {modalsStore: {modal, changeStateModal}} = useStores();
  const [size, setSize] = useState<TSize>(false);

  const watcherResize = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth >= 768) setSize('md')
    if (innerWidth < 768) setSize('sm')
  }

  useEffect(() => {
    watcherResize()
    addEventListener('resize', watcherResize)
    return () => {
      removeEventListener('resize', watcherResize)
    }
  }, [])

  return (
    <Dialog
      onClose={() => {
        changeStateModal()
      }}
      TransitionComponent={Transition}
      keepMounted
      open={modal.open}
      maxWidth={size}
      fullWidth={true}
    >
      <DialogTitle>
        {modal.title}
      </DialogTitle>
      {modal.content && (
        <DialogContent>
          {getChildren(modal.name, modal.args)}
        </DialogContent>
      )}
    </Dialog>
  )
})

export default SimpleDialog;
