import React, {FC} from "react";
import {CircularProgress} from "@material-ui/core";
import s from './loader.module.scss'

const Loader: FC<any> = (props) => (
  <div className={s.loader}>
    <CircularProgress {...props}/>
  </div>
)

export default Loader;
