import React, {FC, useLayoutEffect} from "react";
import {Route, Switch, Redirect, useRouteMatch, useHistory} from "react-router-dom";
import {observer} from "mobx-react";

import Header from "./header";
import DirectionScreen from "./directionScreen";
import AmountScreen from "./amountScreen";
import EmailScreen from "./profile/emailScreen";
import NameScreen from "./profile/nameScreen";
import BirthdayScreen from "./profile/birthdayScreen";
import Loader from "../shared/loader";

import {ROUTES} from "../../util/routes";
import {useStores} from "../../store/useStore";

import s from './RemitlyPage.module.scss';


const RemitlyPage: FC = observer(() => {
  const {path} = useRouteMatch();

  const {userStore: {loading, stateUser}} = useStores();

  let history = useHistory();

  useLayoutEffect(() => {
    if (!stateUser && !loading) {
      history.push("/login")
    }
  }, [])

  if (loading) {
    return (
      <div className={s.wrapperLoader}>
        <Loader/>
      </div>
    )
  }

  return (
    <>
      <Header/>
      <div className={s.main}>
        <Switch>
          <Route path={`${path}/${ROUTES.DIRECTION}`} component={DirectionScreen}/>
          <Route path={`${path}/${ROUTES.AMOUNT}`} component={AmountScreen}/>
          <Route path={`${path}/${ROUTES.PROFILE.ROOT}/${ROUTES.PROFILE.EMAIL}`} component={EmailScreen}/>
          <Route path={`${path}/${ROUTES.PROFILE.ROOT}/${ROUTES.PROFILE.NAME}`} component={NameScreen}/>
          <Route path={`${path}/${ROUTES.PROFILE.ROOT}/${ROUTES.PROFILE.BIRTHDAY}`} component={BirthdayScreen}/>
          <Redirect from="*" to={`${path}/${ROUTES.DIRECTION}`}/>
        </Switch>
      </div>
    </>

  )
});

export default RemitlyPage;
