import React, {FC} from "react";
import {Button, CircularProgress, MenuItem, Select} from "@material-ui/core";
import {CircleFlag} from "react-circle-flags";

import strings from "../../util/i18n/strings";
import {getCountryName} from "../../util/countries";
import {TSubscription} from "../../store/types";

import s from "./SubscriptionSection.module.scss";

type Props = {
  isMobile: boolean,
  item: TSubscription,
  items: TSubscription[],
  handleChange: (value: string) => void,
  activateSubscription: (id: string | undefined) => void,
  loading: boolean,
  link: string
}

const NotActiveSubscription: FC<Props> = ({
                                            isMobile,
                                            item,
                                            items,
                                            handleChange,
                                            activateSubscription,
                                            loading,
                                            link
                                          }) => {

  const welcome = item.isWelcomeOffer;

  return (
    <div className={s.subscription}>
      <div className={s.toolbar}>
        {welcome ? strings.subscription.header : strings.subscription.header_active}
        {!isMobile && (
          <a href={link} target="_blank">
            {strings.subscription.header_link}
          </a>
        )}
      </div>
      <div className={s.content}>
        <div className={s.data}>
          <Select
            value={item ? item.country : ''}
            onChange={(event) => handleChange(String(event.target.value))}
          >
            {items.map((el) => (
              <MenuItem key={el.id} value={el.country}>
                <div className={s.flagContainer}>
                  <div className={s.flag}>
                    <CircleFlag countryCode={el.country.toLowerCase()}/>
                  </div>
                  {getCountryName(el.country)}
                </div>
              </MenuItem>
            ))}
          </Select>
          <div className={s.duration}><span>{item?.volume} min</span> for {item.days} days</div>
          <div className={s.volume}>
            <div className={s.amount}>{item?.amount}$</div>
            <div className={s.text}>excl. VAT</div>
          </div>
        </div>
        {welcome && (
          <div className={s.message}>
            {strings.subscription.inactive_subscription
              .replace('/amount', item ? `${String(item.renewAmount)}$` : '')
              .replace('/duration', item ? String(item.renewVolume) : '')
              .replace('/days', item ? String(item.renewDays) : '')
            }
            <div className={s.activate}/>
          </div>
        )}
        <div className={s.textContent}>
          {welcome ? (
            <>
              {strings.subscription.text_content.split('\n').map((text, idx) => (
                <p key={idx}>{text}</p>
              ))}
            </>
          ) : (
            <>
              {strings.subscription.active_subscription_text.split('\n').map((text, idx) => (
                <p key={idx}>{text}</p>
              ))}
            </>
          )}

        </div>
        <div className={s.footer}>
          <div className={s.info}>
            <label>
              {strings.balance.payments_methods_text}
            </label>
            <div className={s.flexItem}>
              <div className={s.mastercardIcon}/>
              <div className={s.visaIcon}/>
            </div>
            <div className={s.clear}/>
          </div>
          {!isMobile && (
            <Button
              variant="contained"
              color="secondary"
              className={s.mobileBtn}
              onClick={() => {
                activateSubscription(item?.id)
              }}
              disabled={loading}
            >
              {loading ?
                <CircularProgress color="inherit" size={32}/> :
                `${strings.subscription.btn_text.replace('/number', item ? String(item.amount) : '')}`}
            </Button>
          )}
        </div>
        <div className={s.mobileLink}>
          {isMobile && (
            <div className={s.mobileFooter}>
              <Button
                variant="contained"
                color="secondary"
                className={s.mobileBtn}
                onClick={() => {
                  activateSubscription(item?.id)
                }}
                disabled={loading}
              >
                {loading ?
                  <CircularProgress color="inherit" size={32}/> :
                  `${strings.subscription.btn_text.replace('/number', item ? String(item.amount) : '')}`}
              </Button>
              <a href={link} target="_blank">
                {strings.subscription.header_link}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotActiveSubscription;
