import {createTheme} from '@material-ui/core/styles';
import {cyan, grey} from '@material-ui/core/colors';

const primary1Color = cyan[500];

const theme = createTheme({
  fontFamily: 'Rubik, sans-serif',
  typography: {
    fontFamily: 'Rubik, sans-serif',
  },
  MuiButtonBase: {
    root: {
      twitter: {
        backgroundColor: primary1Color
      }
    }
  },
  MuiButton: {
    contained: {
      color: '#4bb32e',
    },
  },
  palette: {
    // @ts-ignore
    primary1Color: primary1Color,
    primary2Color: cyan[700],
    primary3Color: grey[400],
    alternateTextColor: '#fff',
    canvasColor: '#fff',
    grayButtonColor: '#efefef',
    snackbarSuccessColor: '#4bb32e',
    orangeButtonColor: '#fdd835'
  },
  overrides: {
    MuiTextField: {
      root: {
        focusColor: primary1Color,
        underlineFocusStyle: {borderBottomColor: primary1Color}
      },
    },
    MuiInputLabel: {
      filled: {
        transform: 'translate(12px, 18px) scale(1)',
        "&$shrink": {
          transform: 'translate(12px, 4px) scale(0.75)',
          color: 'rgb(0, 188, 212)',
        }
      },
    },
    MuiFilledInput: {
      root: {
        height: '50px',
        backgroundColor: '#fff',
        '&:hover': {
          backgroundColor: '#fff !important'
        },
        '&$focused': {
          backgroundColor: '#fff !important'
        },
        '&:focus': {
          backgroundColor: '#fff !important'
        },
        '&$disabled': {
          backgroundColor: '#fff !important'
        }
      },
      input: {
        height: '100%',
        boxSizing: 'border-box',
        padding: '15px 12px 5px 12px'
      },
      underline: {
        "&:before": {
          borderBottom: `1px solid ${grey[400]}`
        },
        '&:after': {
          borderBottom: `2px solid ${primary1Color}`
        },
        '&:hover:not($disabled):before': {
          borderBottom: `1px solid ${grey[400]}`
        },
      },
    },
    MuiInputAdornment: {
      filled: {
        marginTop: '10px !important',
      }
    },
    MuiInput: {
      root: {
        color: '#636A70',
        lineHeight: "24px",
        fontFamily: 'Rubik, sans-serif',
      },
      underline: {
        "&:before": {
          borderBottom: `1px solid ${grey[400]}`
        },
        '&:after': {
          borderBottom: `2px solid ${primary1Color}`
        },
        '&:hover:not($disabled):before': {
          borderBottom: `1px solid ${grey[400]}`
        },
      },
      colorSecondary: {
        border: '0'
      }
    },
    MuiFormControlLabel: {
      root: {
        paddingTop: '2px',
        display: 'flex',
        alignItems: 'center',
      },
      label: {
        paddingLeft: '10px'
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "black",
        '&$checked': {
          color: primary1Color
        }
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Rubik, sans-serif',
        textTransform: 'none',
        minHeight: '43px',
        paddingRight: '24px',
        paddingLeft: '24px',
        borderRadius: '12px',
        boxShadow: 'none'
      },
      text: {
        paddingRight: '16px',
        paddingLeft: '16px'
      },
      outlinedPrimary: {
        color: 'rgb(0, 188, 212)',
        border: 0,
        '&:hover': {
          color: 'rgb(0,204,231)',
          border: 0,
        },
        '&:disabled': {
          border: 0
        }
      },
      containedPrimary: {
        backgroundColor: 'rgb(0, 188, 212)',
        '&:hover': {
          backgroundColor: 'rgb(0,204,231)',
        }
      },
      containedSecondary: {
        boxShadow: 'none',
        backgroundColor: '#FF8554',
        color: '#fff',
        // textTransform: 'capitalize',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#FF9C74 !important',
        },
        '&$disabled': {
          backgroundColor: '#FFBA9E !important',
          color: '#fff !important',
        },
        "&:active": {
          boxShadow: "none !important"
        }
      }
    },
    MuiDialog: {
      root: {
        fontFamily: 'Rubik, sans-serif',
      }
    },
    MuiDialogTitle: {
      root: {
        fontFamily: 'Rubik, sans-serif',
        padding: '24px 24px 20px',
        fontWeight: 500,
        color: "#636A70"
      },
    },
    MuiDialogContent: {
      root: {
        color: "#636A70"
      }
    },
    MuiDialogActions: {
      root: {
        padding: "8px 0"
      },
    },
    MuiSwitch: {
      root: {},
      switchBase: {
        color: "#ccc",
        bottom: 0
      },
      colorPrimary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: "rgb(0,204,231) !important"
        }
      },
      thumb: {
        color: "#fff",
        '&:hover': {
          color: "#fff !important",
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: "#4BCFD0",
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: "#4BCFD0 !important",
        }
      }
    },
    MuiTypography: {
      h6: {
        padding: 0,
        fontWeight: 'inherit'
      }
    },
    MuiCircularProgress: {
      root: {
        "& .MuiLinearProgress-colorPrimary": {
          color: "red",
        },
      },
      colorPrimary: {
        color: '#FF8554'
      },
      colorSecondary: {
        color: 'rgb(0, 188, 212)'
      }
    }
  },
});

export default theme
