import React, {FC} from "react";
import {observer} from "mobx-react";

import {TUser} from "../../store/types";
import {useStores} from "../../store/useStore";
import strings from "../../util/i18n/strings";

import s from "./AccountSecton.module.scss";

type Props = {
  user: TUser | null
}

const AccountSection: FC<Props> = observer(() => {

  const {userStore: {user}} = useStores();

  return (
    <section className={s.account}>
      <div className={s.toolbar}>
        {strings.profile.header}
      </div>
      <div className={s.content}>
        <div className={s.userFrame}>
          <div style={user?.avatar ? {backgroundImage: 'url(' + user.avatar + ')'} : {}} className={s.avatar}/>
        </div>
        <div className={s.info}>
          <div>
            <strong>
              {user?.first_name !== null && user?.last_name !== null ?
                `${user?.first_name} ${user?.last_name}` : strings.profile.phone_number
              }
            </strong>
          </div>
          <div>{user?.phone}</div>
          <div>{user?.email ? user.email : strings.footer.email_is_not_specified}</div>
        </div>
      </div>
    </section>
  )
})

export default AccountSection;

