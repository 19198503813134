import React, {FC} from "react";
import moment from "moment";

import strings from "../../../../util/i18n/strings";

import s from "../../HistorySection.module.scss";

type Props = {
  record: any
}

export const RefundRecord: FC<Props> = ({record}) => (
  <tr>
    <td className={s.bold}>{strings.history.row_content_refund}</td>
    <td colSpan={3}>{moment(new Date(record.timestamp * 1000)).format("D MMM HH:mm")}</td>
    <td className={s.rate}/>
    <td>${Math.abs(record.amount).toFixed(3)}</td>
  </tr>
)
