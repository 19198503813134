import React, {FC} from "react";
import {observer} from "mobx-react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

import {useStores} from "../../../store/useStore";
import strings from "../../../util/i18n/strings";

const DownloadDataModal: FC = observer(() => {

  const {
    userStore: {
      user,
      sendDataToEmail,
      deleteReport,
      openReportLink
    },
    modalsStore: {
      changeStateSnackbar,
      changeStateModal
    }
  } = useStores();

  const sendData = () => {
    sendDataToEmail()
      .catch((err) => {
        changeStateSnackbar(err)
      })
  }

  const handleDeleteReport = () => {
    deleteReport()
      .catch((err) => {
        changeStateSnackbar(err)
      })
  }

  return (
    <>
      <DialogContentText>
        {strings.download_modal.text}
      </DialogContentText>
      <DialogActions>
        <Button onClick={() => changeStateModal()}>
          {strings.buttons_modal.cancel_btn}
        </Button>
        {user && user.personal_data_request_state === 'ready' &&
        <Button onClick={handleDeleteReport}>
          {strings.buttons_modal.delete_btn}
        </Button>
        }
        {user && user.personal_data_request_state === 'none' ?
          <Button color="primary" variant="outlined" onClick={sendData}>
            {strings.download_modal.request_report_btn}
          </Button>
          :
          (user && user.personal_data_request_state === 'ready' &&
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                openReportLink()
              }}
              startIcon={<GetAppIcon/>}
            >
              {strings.download_modal.download_report_btn}
            </Button>
          )
        }
      </DialogActions>
    </>
  )
})

export default DownloadDataModal;
