import React from "react";
import {observer} from 'mobx-react';
import {useStores} from "../../store/useStore";
import Table from "./Table";

const HistorySection = observer(() => {

  const {
    historyStore: {
      getHistoryAsync,
      loading,
      history,
      hasMore
    },
    modalsStore: {
      changeStateSnackbar
    }
  } = useStores();

  const onMoreClick = () => {
    getHistoryAsync()
      .catch((err) => {
        changeStateSnackbar(err)
      })
  }

  return (
    <Table
      loading={loading}
      history={history}
      hasMore={hasMore}
      onMoreClick={onMoreClick}
    />
  )
})

export default HistorySection;
