import React, {FC} from "react";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {Button, DialogActions, DialogContent, DialogContentText,} from "@material-ui/core";

import {useStores} from "../../../store/useStore";
import strings from "../../../util/i18n/strings";

const LogoutModal: FC = observer(() => {
  let historyUser = useHistory();
  const {
    userStore: {
      logoutAsync
    },
    historyStore: {
      setInitialState
    },
    modalsStore: {
      changeStateModal
    }
  } = useStores();

  const handleLogout = () => {
    setInitialState();
    logoutAsync();
    changeStateModal();
    historyUser.push({
      pathname: '/login',
      state: 'redirect'
    })
  };
  return (
    <>
      <DialogContentText>
        {strings.logout_modal.text}
      </DialogContentText>
      <DialogActions>
        <Button onClick={() => changeStateModal()}
        >
          {strings.buttons_modal.cancel_btn}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleLogout}
        >
          {strings.logout_modal.log_out_btn}
        </Button>
      </DialogActions>
    </>
  )
})

export default LogoutModal;
