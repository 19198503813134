import React, {FC} from "react";
import {observer} from "mobx-react";
import {useLocation} from "react-router-dom";

import {useStores} from "../../../store/useStore";
import {ROUTES} from "../../../util/routes";
import HeaderContent from "./HeaderContent";
import strings from "../../../util/i18n/strings";

import s from './header.module.scss'

type Props = {
  siteUrl: string
}

const HeaderContainer: FC<Props> = observer(({siteUrl}) => {
  const {pathname} = useLocation();
  const {
    userStore: {
      stateUser,
      user,
    },
    modalsStore: {
      changeStateModal
    }
  } = useStores();

  const openDownloadModal = () => {
    changeStateModal(strings.download_modal.title, true, true, 'download')
  }

  const openSettingsModal = () => {
    changeStateModal(strings.settings_modal.title, true, true, 'settings')
  }

  const openDeleteAccountModal = () => {
    changeStateModal(strings.delete_modal.title, true, true, 'delete')
  };

  const openLogoutModal = () => {
    changeStateModal(strings.logout_modal.title, true, true, 'logout')
  };

  if(pathname.includes(ROUTES.REMITLY)) {
    return null
  }

  return (
    <div className={s.header}>
      <HeaderContent
        user={user}
        siteUrl={siteUrl}
        stateUser={stateUser}
        openDownload={openDownloadModal}
        openSettings={openSettingsModal}
        openDelete={openDeleteAccountModal}
        openLogout={openLogoutModal}
      />
    </div>
  );
})

export default HeaderContainer;
