import moment from "moment";
import {instance} from "./root";
import {TCalculations, TCalculationsUserData, TUrl} from "./types";

export const remitlyAPI = {
  getSources() {
    return instance.get("/v2/remitly/sources")
  },
  getTargets(target: string) {
    return instance.get(`/v2/remitly/targets?fromCountry=${target}`)
  },
  getPackages(from: string, to: string) {
    return instance.get(`/v2/remitly/packages?fromCountry=${from}&toCountry=${to}`)
  },
  getCalculations(params: TCalculations & Partial<TCalculationsUserData>) {
    const data = {
      ...params
    }
    if (params.birthDate) {
      data.birthDate = moment(params.birthDate).format('YYYY-MM-DD')
    }
    return instance.post('/v2/remitly/calc', {
      ...data
    })
  },
  postRemitlyData(params: TCalculationsUserData & TUrl) {
    const data = {
      first_name: params.firstName,
      last_name: params.lastName,
      date_of_birth: moment(params.birthDate).format('YYYY-MM-DD')
    };

    return instance({
      method: "POST",
      url: params.url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        ...data
      }
    })
  }
}
