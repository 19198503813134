export enum FieldKey {
  Amount = 'amount',
  ToCurrency = 'toCurrency',
  Package = 'package',
  ReceiveAmount = 'receiveAmount',
  FromCountry = 'fromCountry',
  FromCurrency = 'fromCurrency',
  ToCountry = 'toCountry'
}


export type TOnChangeAmountScreen = FieldKey.Amount | FieldKey.ToCurrency | FieldKey.Package | FieldKey.ReceiveAmount;
