import {runInAction, makeAutoObservable} from 'mobx';

import {userAPI} from "../api";
import {THistory} from "./types";

export const DEFAULT_HISTORY_LIMIT = 3;
export const MORE_HISTORY_LIMIT = 20;

export class HistoryStore {
  history: THistory[] = [];
  loading: boolean = true;
  limit: number = DEFAULT_HISTORY_LIMIT;
  hasMore: boolean = false;

  constructor() {
    makeAutoObservable(this)
  }

  loadHistory(items: THistory[], haseMoreData: boolean) {
    this.hasMore = haseMoreData;
    this.limit = this.limit > 3 ? (this.limit + MORE_HISTORY_LIMIT) : MORE_HISTORY_LIMIT;
    this.history = items;
    this.loading = false;
  }

  setInitialState = () => {
    this.history = [];
    this.loading = true;
    this.limit = DEFAULT_HISTORY_LIMIT;
    this.hasMore = false;
  }

  getHistoryAsync = async () => {
    this.loading = true;
    await userAPI.getHistory(this.limit)
      .then((res) => {
        const {data} = res.data;
        runInAction(() => {
          this.loadHistory(data.records, !!data?.next_time_offset);
        })
      }).catch((err) => {
        return Promise.reject(err)
      })
  }
}
