import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Link} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select,
  Switch
} from "@material-ui/core";

import {useStores} from "../../store/useStore";
import {TCards, TProducts} from "../../store/types";
import strings from "../../util/i18n/strings";

import s from "./CardSection.module.scss";

const getTypeCard = (type: string) => {
  switch (type) {
    case 'mastercard':
      return s.masterCard;
    case 'visa':
      return s.visa;
    default:
      return s.other;
  }
}

const CardsSection: FC = observer(() => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [defaultType, setDefaultType] = useState<number | string>('');

  const {
    paymentsStore: {
      cards,
      products,
      getCardsAsync,
      selectedCard,
      hasCards,
      deleteCardAsync,
      getProductsAsync,
      getAutoTopupAsync,
      updateAmountAsync,
      deleteAutoFill,
    },
    modalsStore: {
      changeStateSnackbar
    }
  } = useStores()

  useEffect(() => {
    getCardsAsync()
    getProductsAsync()
    getAutoTopupAsync()
  }, [])

  useEffect(() => {
    if (products.length > 0) {
      const findElem = products.find((el: TProducts) => el.selected && selectedCard !== null)
      setDefaultType(findElem ? findElem.amount : defaultType)
    }
  }, [products, selectedCard])


  const handleDelete = () => {
    deleteCardAsync(selected?.id);
    closeDialog()
  }

  const openDialog = (item: TCards) => {
    setSelected(item);
    setOpen(true);
  }

  const closeDialog = () => {
    setSelected(null);
    setOpen(false);
  }

  if (!hasCards) {
    return null
  }

  const setDefault = (id: string) => {
    updateAmountAsync(defaultType ? Number(defaultType) : 4, id)
      .catch((err) => {
        changeStateSnackbar(err)
      })
  }

  const changeAmount = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateAmountAsync(Number(event.target.value))
      .catch((err) => {
        changeStateSnackbar(err)
      })
  }

  const handleChangeSwitch = () => {
    if (selectedCard !== null) {
      deleteAutoFill()
    } else {
      updateAmountAsync(defaultType ? Number(defaultType) : 4, cards[0].id)
        .catch((err) => {
          changeStateSnackbar(err)
        })
    }
  }

  const getLink = () => {
    switch (strings.getLanguage()) {
      case 'ru':
        return 'https://yollacalls.ru/credentials-storage-agreement-new/';
      case 'de':
        return 'https://yollacalls.de/credentials-storage-agreement-new/';
      case 'fr':
        return 'https://yollacalls.fr/credentials-storage-agreement-new/';
      default:
        return 'https://yollacalls.com/credentials-storage-agreement-new/'
    }
  }

  return (
    <div className={s.mainWrap}>
      <div className={`${s.wrapper} ${s.cardsWrap}`}>
        <div className={s.toolbar}>
          {strings.cards.header}
        </div>
        <div className={s.cards}>
          <div className={s.titleCards}>
            {strings.cards.title_cards}
          </div>
          {cards.map((el: TCards) => (
              <div className={`${s.card} ${selectedCard !== null ? s.pointer : ''}`} key={el.id} onClick={() => {
                selectedCard !== null ? setDefault(el.id) : false
              }}>
                <div className={s.cardInfo}>
                  <div className={`${s.typeCard} ${getTypeCard(el.vendor)}`}/>
                  <div className={s.numberCard}>{el.name}</div>
                  {selectedCard && selectedCard.id === el.id && <CheckIcon/>}
                </div>
                <div className={s.controlCard}>
                  <CloseIcon className={s.delete} onClick={(e) => {
                    e.stopPropagation();
                    openDialog(el)
                  }}/>
                </div>
              </div>
            )
          )}
        </div>
      </div>

      <div className={s.columnPadding}/>

      <div className={s.wrapper}>
        <div className={s.toolbar}>
          {strings.cards.header_auto_top_up}
        </div>
        <div className={s.autoTopup}>
          <div className={s.switchBox}>
            <div className={s.titleAuto}>{strings.cards.enable_auto_refill}</div>
            <Switch checked={selectedCard !== null} onClick={handleChangeSwitch} disableRipple={true}/>
          </div>
          <div className={s.credits}>
            <Select
              value={defaultType || 4}
              onChange={changeAmount}
              disabled={selectedCard === null}
            >
              {products.map((el: TProducts, index: number) => (
                <MenuItem key={`${index}-${el.title}`} value={el.amount}>
                  {el.title}</MenuItem>
              ))}
            </Select>
          </div>
          <div className={s.footer}>
            {strings.cards.information_auto_refill}
            <br/>
            <div>
              <Link
                to={{pathname: getLink()}}
                target="_blank"
              >
                {strings.cards.agreement_link}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={closeDialog}
      >
        <DialogContent>
          <DialogContentText>
            {strings.cards.questions_modal}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{padding: '0 24px 10px 24px'}}>
          <Button onClick={closeDialog}>
            {strings.buttons_modal.cancel_btn}
          </Button>
          <Button onClick={handleDelete}>
            {strings.buttons_modal.delete_btn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
})

export default CardsSection;
