import React, {FC} from "react";
import moment from "moment";
import {Switch} from "@material-ui/core";
import {CircleFlag} from "react-circle-flags";

import strings from "../../util/i18n/strings";
import {getCountryName} from "../../util/countries";
import {TSubscription} from "../../store/types";

import s from "./SubscriptionSection.module.scss";

type Props = {
  link: string,
  isMobile: boolean,
  item: TSubscription,
  changeStatusSubscription: (status: boolean, id: string) => void,
}

const ActiveDemoSubscription: FC<Props> = ({link, isMobile, item, changeStatusSubscription}) => (
  <div className={s.subscription}>
    <div className={s.toolbar}>
      {strings.subscription.header}
      {!isMobile && (
        <a href={link} target="_blank">
          {strings.subscription.header_link}
        </a>
      )}
    </div>
    <div className={s.content}>
      <div className={s.data}>
        <div className={s.flagContainer}>
          <div className={s.flag}>
            <CircleFlag countryCode={item.country.toLowerCase()}/>
          </div>
          {getCountryName(item.country)}
        </div>
        <div className={s.duration}>
          <div className={`${item.remain > 0 ? s.counter : s.counterRed}`}>
            {`${item.remain}/${item.volume} ${strings.subscription.time_format_demo_subscription}`}
          </div>
          <div>
            {`${strings.subscription.expires_on} ${moment.unix(item.validTo).format('MMM DD, YYYY')}`}
          </div>
        </div>
        <div className={s.volume}>
          <div className={s.amount}>{item?.amount}$</div>
        </div>
      </div>
      <div className={s.message}>
        {strings.subscription.active_subscription_demo
          .replace('/amount', item ? `${String(item.renewAmount)}$` : '')
          .replace('/duration', item ? String(item.renewVolume) : '')
          .replace('/days', item ? String(item.renewDays) : '')
        }
        <div>
          <Switch
            checked={item.autoRenew}
            onClick={() => changeStatusSubscription(!item.autoRenew, item.id)}
            disableRipple={true}
          />
        </div>
      </div>
      <div className={s.textContentSub}>
        {strings.subscription.text_content.split('\n').map((text, idx) => (
          <p key={idx}>{text}</p>
        ))}
      </div>
      <div className={s.mobileLink}>
        {isMobile && (
          <a href={link} target="_blank">
            {strings.subscription.header_link}
          </a>
        )}
      </div>
    </div>
  </div>
)

export default ActiveDemoSubscription;
