import React, {useEffect, useRef, FC, useState} from "react";
import {Button, TextField, CircularProgress, InputAdornment} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {useHistory} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

import strings from "../../util/i18n/strings";
import { privacyInformation } from "../shared/footer/helper";
import {userAPI} from "../../api";
import InstallDialog from "../modals/InstallDialog";

import s from "./login.module.scss";
import {ERROR_NUMBER_VALID} from "../../api/root";
import ValidModal from "../modals/validModal";

const ENTER_CODE = 13;

type Props = {
  phone: string | undefined,
  error: any | null,
  code: string | null,
  onSubmit: Function,
  inProgress: boolean,
}

const LoginForm: FC<Props> = ({phone, error, code, onSubmit, inProgress}) => {
  const [state, setState] = useState({
    installDialog: false,
    notValidDialog: false,
    isUrlAuthorization: false,
  });

  const phoneInput = useRef<HTMLInputElement>();
  const codeInput = useRef<HTMLInputElement>();

  let history = useHistory();

  const recaptchaRef = useRef<any>();

  const openInstallDialog = () => {
    setState({
      ...state,
      installDialog: true
    })
  }


  const openNumberNotValid = () => {
    setState({
      ...state,
      notValidDialog: true
    })
  }

  useEffect(() => {
    setState({
      ...state,
      isUrlAuthorization: window.location.href.indexOf('at=') !== -1 && !userAPI.isAuthorized()
    })
    setTimeout(() => {
      if (userAPI.isAuthorized()) {
        setState({
          ...state,
          isUrlAuthorization: false
        })
      }
    }, 2000)
    setTimeout(() => {
      setState({
        ...state,
        isUrlAuthorization: false
      })
    }, 5000);
  }, [codeInput, phoneInput])

  const handleChangeNumberClick = () => {
    history.go(0)
  };

  const handleKeyPress = (target: React.KeyboardEvent) => {

    if (target.charCode === ENTER_CODE) {
      handleSubmit(target);
    }
  };

  const [phoneLength, setPhoneLength] = useState<string>('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    if (!inProgress && token !== null && token !== undefined) {
      const phoneUser = phone ? phone : phoneInput?.current?.value;
      const code = codeInput.current ? codeInput.current.value : null;
      onSubmit(phoneUser, code, token);
    }
  }

  const handleInstallDialogClose = () => {
    setState({
      ...state,
      installDialog: false
    });
  };

  const handleNotValidDialogClose = () => {
    setState({
      ...state,
      notValidDialog: false
    });
  };

  if (state.isUrlAuthorization) {
    return (
      <div className={s.whiteScreen}>
        <div className={s.refreshIndicator}>
          <CircularProgress style={{zIndex: 100, margin: '0 auto'}}/>
        </div>
      </div>
    )
  }

  return (
    <div className={s.bg}>
      <section className={s.signIn}>
        <div className={s.titleLogin}>
          {strings.login.title}
        </div>
        {/*<div className={s.subtitleLogin}>*/}
        {/*  {strings.login.sign_up_btn}*/}
        {/*</div>*/}
        <form onSubmit={handleSubmit}>
          {phone ?
            <div>
              <div className={s.label}>
                {strings.login.field_label_phone}
              </div>
              <TextField
                variant="filled"
                disabled={true}
                value={phone}
                className={s.input}
                placeholder={strings.login.field_label_phone}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" id={s.wrapperPlus}>
                      <AddIcon fontSize="small" className={s.plus}/>
                    </InputAdornment>
                  ),
                }}
              />
              <br/>
              <div className={`${s.label} ${s.p30}`}>
                {strings.login.field_label_code}
              </div>
              <TextField
                variant="filled"
                placeholder="xxxx"
                inputRef={codeInput}
                defaultValue={code}
                onKeyPress={handleKeyPress}
                helperText={error ? error : null}
                error={!!error}
                className={s.input}
              />
              <br/>
              <p style={{maxWidth: 450}}>
                {strings.login.verification_code_text}
                <br/>
                <Button
                  variant="text"
                  className={s.anotherNumber}
                  disableRipple={true}
                  onClick={handleChangeNumberClick}
                >
                  {strings.login.another}
                </Button>
              </p>
              <Button
                variant="contained"
                disabled={phoneLength.length < 7}
                color="secondary"
                type="submit"
              >
                {strings.login.proceed_btn}
              </Button>
            </div>
            :
            <div>
              <div>
                <div className={s.label}>
                  {strings.login.field_label_phone}
                </div>
                <TextField
                  variant="filled"
                  type="number"
                  inputRef={phoneInput}
                  onChange={event => setPhoneLength(event.target.value)}
                  onKeyPress={handleKeyPress}
                  error={!!error}
                  className={s.input}
                  placeholder={strings.login.field_label_phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" id={s.wrapperPlus}>
                        <AddIcon fontSize="small" className={s.plus}/>
                      </InputAdornment>
                    ),
                  }}
                />
                {error && (
                  <>
                    {error === ERROR_NUMBER_VALID ? (
                      <div className={`${s.errorMessage} ${s.setErrorMessage}`}>
                        {strings.login.error_number_valid.split('\n').map((text: any, index: number) => {
                          const link = text.split('/link');
                          if (link.length === 2) {
                            return <div key={index}>{`${link[0]} `}
                              <span
                                className={s.linkAppA}
                                onClick={openNumberNotValid}>
                                {link[1]}
                          </span>
                            </div>
                          }
                          return <div key={index}>{text}</div>
                        })}
                      </div>
                    ): (
                      <div className={s.errorMessage}>
                        {error?.split('\n').map((text: any, index: number) => {
                          if (index === 1) {
                            return <div>{`${text} `}
                              <span
                                className={s.linkApp}
                                onClick={openInstallDialog}>
                            {strings.login.link_error}
                          </span>
                            </div>
                          }
                          return <div>{text}</div>
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={s.text}>
                {strings.login.info_start}
                <br/>
                {strings.login.info_end}
              </div>
              <div>
                <Button
                  variant="contained"
                  disabled={inProgress || phoneLength.length < 7}
                  color="secondary"
                  type="submit"
                >
                  {strings.login.sign_up_btn}
                </Button>
              </div>
              <div className={`${s.text} ${s.text_small}`}>
                {strings.login.GDPR_start} <a href={`${strings.site_url}/${privacyInformation[0].link}`}>{privacyInformation[0].name}</a> {strings.login.GDPR_and} <a href={`${strings.site_url}/${privacyInformation[1].link}`}>{privacyInformation[1].name}</a>
              </div>
            </div>
          }
          <>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
            />
          </>
        </form>
      </section>
      {state.installDialog &&
      <InstallDialog onClose={handleInstallDialogClose}/>
      }
      {state.notValidDialog && (
        <ValidModal onClose={handleNotValidDialogClose}/>
      )}
    </div>
  )
}


export default LoginForm;
