import React, {FC} from "react";
import moment from "moment";

import {getCountryName} from "../../../../util/countries";

import s from "../../HistorySection.module.scss";
import strings from "../../../../util/i18n/strings";

type Props = {
  record: any
}

export const SubscriptionRecord: FC<Props> = ({record}) => {
  const flagSrc = `https://static.yollacalls.com/app/flag/${record.subscription_iso}.png`

  return (
    <tr>
      <td className={s.bold}>{record.subscription_name} {strings.history.row_content_subscription}</td>
      <td>{moment(new Date(record.timestamp * 1000)).format("D MMM HH:mm")}</td>
      <td><img src={flagSrc} className={s.flag} alt=""/><span>{getCountryName(record.subscription_iso)}</span></td>
      <td>{record.subscription_volume} {strings.history.row_content_min}</td>
      <td className={s.rate}/>
      <td>${Math.abs(record.amount)}</td>
    </tr>
  )
};
