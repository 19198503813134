import React, {FC} from "react";
import moment from "moment";

import strings from "../../../../util/i18n/strings";

import s from '../../HistorySection.module.scss'

type Props = {
  record: any
}

export const BonusRecord: FC<Props> = ({record}) => (
  <tr className={s.topup}>
    <td className={s.bold}>{strings.history.row_content_bonus}</td>
    <td colSpan={3}>{moment(new Date(record.timestamp * 1000)).format("D MMM HH:mm")}</td>
    <td className={s.rate}/>
    <td>+ ${record.amount}</td>
  </tr>
)
