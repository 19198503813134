import React, {FC, useEffect, useState} from "react";
import {Button, FormControl, InputLabel, OutlinedInput, FormHelperText, CircularProgress} from "@material-ui/core";
import {useFormik} from "formik";
import {observer} from "mobx-react";
import * as yup from "yup";
import MaskedInput from 'react-text-mask';
import LockIcon from '@material-ui/icons/Lock';
import {useHistory} from "react-router-dom";
import moment from "moment";

import {useStores} from "../../../../store/useStore";
import strings from "../../../../util/i18n/strings";
import {ROUTES} from "../../../../util/routes";
import {REMITLY_PAYMENT} from "../../../../api/root";
import {postDOMForm} from "../../../../util/utils";

import s from "../../RemitlyPage.module.scss";

const validationSchema = yup.object({
  // @ts-ignore
  birthDate: yup.string().required('Field required').test('birth-date', 'Enter valid date', function (value) {
    if (value === undefined) {
      return false
    }
    const parts = value?.split("/");
    if (parts?.length !== 3) return false
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    if (String(year).length !== 4) return false;
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;
    return day > 0 && day <= monthLength[month - 1];
  })
})

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (props: TextMaskCustomProps) => {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

const BirthdayScreen: FC = observer(() => {

  const [isLoading, setLoading] = useState<boolean>(false);

  let history = useHistory();

  const {
    remitlyStore: {
      getFieldValue,
      setFieldValues,
      sendDataAsync,
      fields
    },
    modalsStore: {
      changeStateSnackbar
    }
  } = useStores();

  useEffect(() => {
    if (getFieldValue('fromCountry') === '') {
      history.push(`/${ROUTES.REMITLY}/${ROUTES.DIRECTION}`)
    }
  }, [])

  useEffect(() => {
    if (getFieldValue('fromCurrency') === '') {
      history.push(`/${ROUTES.REMITLY}/${ROUTES.AMOUNT}`)
    }
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  })

  const saveValue = getFieldValue('birthDate') ? moment(getFieldValue('birthDate')).format('DD/MM/YYYY') : '';

  const formik = useFormik({
    initialValues: {
      birthDate: saveValue,
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: ((values) => {
      setLoading(true);
      const date = moment(values.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      setFieldValues({birthDate: date});
      sendDataAsync()
        .then((res) => {
          const saveFields = {
            firstName: fields.firstName,
            lastName: fields.lastName,
            birthDate: date,
            email: fields.email,
          }
          localStorage.setItem(REMITLY_PAYMENT, JSON.stringify(saveFields));
          const {data} = res;
          postDOMForm(data.paymentUrl, {
            first_name: fields.firstName,
            last_name: fields.lastName,
            date_of_birth: date
          }, 'enctype')
        }).catch((err) => {
        setLoading(false);
        changeStateSnackbar(err)
      })
    })
  })

  const {handleSubmit, handleChange, values, isValid, errors} = formik;

  const getValid = () => {
    const arrayValid = Object.entries(values).filter(([key, value]) => value !== '');
    return arrayValid.length === 0;
  }

  return (
    <div className={s.wrapper}>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={s.title}>
          {strings.birthday_screen.title}
        </div>
        <div className={s.subtitle}>
          {strings.birthday_screen.subtitle}
        </div>
        <div className={s.row}>
          <FormControl fullWidth={true} variant="outlined">
            <InputLabel
              htmlFor="birthday"
              className={s.label}>
              {strings.birthday_screen.field_label_birthday}
            </InputLabel>
            <OutlinedInput
              value={values.birthDate}
              onChange={handleChange}
              name="birthDate"
              id="birthDate"
              placeholder="DD/MM/YYYY"
              variant="outlined"
              className={s.customField}
              error={Boolean(errors.birthDate)}
              // @ts-ignore
              inputComponent={TextMaskCustom}
            />
          </FormControl>
        </div>
        {errors.birthDate && (
          <FormHelperText className={s.error}>{errors.birthDate}</FormHelperText>
        )}
        <div className={`${s.row} ${s.birthDateButton}`}>
          {isLoading ?
            <div className={s.wrapProgress}>
              <CircularProgress/>
            </div> :
            <Button
              type="submit"
              className={s.submit}
              variant="contained"
              color="primary"
              disabled={!isValid || getValid()}
            >
              {strings.buttons_modal.continue_btn}
            </Button>
          }
        </div>
        <div className={s.info}>
          <LockIcon/>
          <div>
            {strings.protected_info}
          </div>
        </div>
      </form>
    </div>
  )
})

export default BirthdayScreen;

