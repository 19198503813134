import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react";
import {Button, CircularProgress} from "@material-ui/core";

import {useStores} from "../../store/useStore";
import {TCredits} from "../../store/types";
import {ROUTES} from "../../util/routes";
import {postDOMForm} from "../../util/utils";

import strings from "../../util/i18n/strings";
import Loader from "../shared/loader";

import s from './Payment.module.scss'

const Payment = observer(() => {

  const [process, setProcess] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(8)

  const {
    paymentsStore: {
      credits,
      getCreditsAsync,
      openPayAsync,
      getGatewaysAsync
    },
    modalsStore: {
      changeStateSnackbar
    },
  } = useStores();

  let history = useHistory();

  useEffect(() => {
    getCreditsAsync()
  }, [])

  const goToMain = () => {
    history.push(ROUTES.ROOT)
  }

  const openPay = async () => {
    let cardGateways: any = {};
    try {
      const types = await getGatewaysAsync(String(selected));
      cardGateways = types.find((el: any) => el.type === 'card');
    } catch (err) {
      changeStateSnackbar(err)
    }
    setProcess(true);
    openPayAsync(String(selected), cardGateways.name)
      .then((res) => {
        const {method, params, url} = res;
        if (method.toUpperCase() === "POST") {
          postDOMForm(url, params)
        } else {
          const link = document.createElement('a');
          link.href = url;
          link.click();
        }
        setProcess(false);
      }).catch((err) => {
      setProcess(false);
      changeStateSnackbar(err)
    })
  }

  const changeSelectedAmount = (amount: number): void => {
    setSelected(amount)
  }

  return (
    <div className={s.bg}>
      {credits.length === 0 ? (
        <Loader color="secondary"/>
      ) : (
        <section className={s.payment}>
          <div className={s.title}>
            {strings.payment.title}
          </div>
          <div className={s.subtitle}>
            {strings.payment.subtitle}
          </div>
          <div className={s.payVariantTitle}>
            {strings.payment.payment_title}
          </div>
          <div className={s.buttons}>
            {process ?
              <CircularProgress size={30}/> : (
                <>
                  {credits.length > 0 && credits.map((product: TCredits) => (
                    <span key={product.id}>
                <Button
                  variant={product.amount === selected ? 'contained' : 'outlined'}
                  color="secondary"
                  className={product.amount === selected ? '' : s.buttonCustom}
                  onClick={() => {
                    changeSelectedAmount(product.amount)
                  }}
                >
                    {product.title}
                </Button>
                      &nbsp;&nbsp;
            </span>
                  ))}
                </>
              )}

          </div>
          <div className={s.text}>
            {strings.payment.credit_text}
          </div>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={openPay}
            >
              {strings.payment.pay_btn}
            </Button>
          </div>
          <Button className={s.back} onClick={goToMain} disableRipple={true}>
            {strings.payinfo.account_btn}
          </Button>
        </section>
      )}

    </div>
  )
})

export default Payment;
