import React, {FC} from "react";

import {
  SmsRecord,
  CallRecord,
  BonusRecord,
  RefundRecord,
  PaymentRecord,
  SubscriptionRecord,
  PaymentRecordWithReceipt,
} from "./rowTable";
import TableFooter from "../TableFooter";
import strings from "../../../util/i18n/strings";

import s from "../HistorySection.module.scss";
import {THistory} from "../../../store/types";
import CallIncoming from "./rowTable/CallIncoming";

type Props = {
  loading: boolean,
  hasMore: boolean,
  history: THistory[],
  onMoreClick: () => void
}

export const Table: FC<Props> = ({loading, history = [], hasMore, onMoreClick}) => {
  return (
    <section className={s.history}>
      <div className={s.toolbar}>
        {strings.history.header}
      </div>
      <div className={s.content}>
        <table>
          <thead>
          <tr>
            <th>
              {strings.history.table_header_number}
            </th>
            <th>
              {strings.history.table_header_date}
            </th>
            <th>
              {strings.history.table_header_country}
            </th>
            <th>
              {strings.history.table_header_duration}
            </th>
            <th>
              {strings.history.table_header_rate}
            </th>
            <th>
              {strings.history.table_header_amount}
            </th>
          </tr>
          </thead>
          {history.length > 0 &&
          <tbody>
          {history.map((record) => {
            switch (record.type) {
              case 'call':
                return <CallRecord record={record} key={record.timestamp}/>;
              case 'sms':
                return <SmsRecord record={record} key={record.timestamp}/>;
              case 'bonus':
                return <BonusRecord record={record} key={record.timestamp}/>;
              case 'topup':
                return record.receipt_id ?
                  <PaymentRecordWithReceipt record={record} key={record.timestamp}/> :
                  <PaymentRecord record={record} key={record.timestamp}/>;
              case 'refund':
                return <RefundRecord record={record} key={record.timestamp}/>;
              case 'subscription':
                return <SubscriptionRecord record={record} key={record.timestamp}/>;
              case 'incoming':
                return <CallIncoming record={record} key={record.timestamp}/>;
            }
          })
          }
          </tbody>
          }
          <TableFooter inProgress={loading} onMoreClick={onMoreClick} hasMore={hasMore}/>
        </table>
      </div>
    </section>
  )
}

export default Table;
