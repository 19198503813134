import React from "react";
import {Redirect, Route, useLocation} from "react-router-dom";

import {ROUTES} from "../routes";
import {observer} from "mobx-react";
import {useStores} from "../../store/useStore";

const PrivateRoute = observer(({component, ...rest}: any) => {

  const {userStore: {stateUser}} = useStores();

  const {pathname, search} = useLocation();

  const no = pathname.includes('no');
  const unsubscribe = pathname.includes('unsubscribe');
  const delAcc = pathname.includes('delete');

  const conditions = ['utm_source', 'click_action'];

  const condition = conditions.some(v => search.includes(v));

  const routeComponent = (props: any) => (
    stateUser
      ? React.createElement(component, props)
      : <Redirect
        to={{
          pathname: `/${ROUTES.LOGIN}${no || unsubscribe ? '/no' : ''}${delAcc ? '/delete' : ''}${condition ? search : ''}`
      }}/>
  );
  return <Route {...rest} render={routeComponent}/>;
})

export default PrivateRoute;
