import React, {FC, useEffect} from "react";
import {useHistory, Switch, Route, Redirect} from "react-router-dom";
import {observer} from "mobx-react";
import {useIntercom} from "react-use-intercom";

import {useStores} from "../../../store/useStore";
import {getUrlParameter} from "../../../util/utils";
import {ACCESS_TOKEN_KEY} from "../../../api/root";
import {ROUTES} from "../../../util/routes";

import MainPage from "../../mainPage";

const ProtectedRoute: FC = observer(() => {

  const history = useHistory();

  const {
    userStore: {
      stateUser
    },
  } = useStores();

  const {shutdown} = useIntercom();

  useEffect(() => {
    const redirectUrl = getUrlParameter("redirect_uri");
    const isAuthorized = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (isAuthorized && redirectUrl && redirectUrl.startsWith("https://oauth-redirect.googleusercontent.com/r/yolla-4a640")) {
      window.location.href = `${redirectUrl}#access_token=${isAuthorized}&token_type=bearer&state=${getUrlParameter('state')}`
    }
  }, [])

  useEffect(() => {
    if (!stateUser) {
      shutdown();
      history.push({
        pathname: `${ROUTES.ROOT}/${ROUTES.LOGIN}`,
        state: 'redirect',
        search: location.search
      })
    }
  }, [])

  return (
    <>
      <Switch>
        <Route path={`${ROUTES.ROOT}`} exact component={MainPage}/>
        <Redirect from="*" to={`${ROUTES.ROOT}`}/>
      </Switch>
    </>
  )
})

export default ProtectedRoute;
