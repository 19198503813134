import React, {FC} from "react";
import moment from "moment";

import {getCountryName} from "../../../../util/countries";
import strings from "../../../../util/i18n/strings";

import s from '../../HistorySection.module.scss'

type Props = {
  record: any
}

export const SmsRecord: FC<Props> = ({record}) => {
  const flagSrc = `https://static.yollacalls.com/app/flag/${record.phone_country_iso}.png`;

  return (
    <tr>
      <td className={s.bold}>{strings.history.row_content_sms} +{record.phone}</td>
      <td>{moment(new Date(record.timestamp * 1000)).format("D MMM HH:mm")}</td>
      <td><img src={flagSrc} className={s.flag} alt=""/><span>{getCountryName(record.phone_country_iso)}</span></td>
      <td>&nbsp;</td>
      <td className={s.rate}>&nbsp;</td>
      <td>${record.amount.toFixed(3)}</td>
    </tr>
  )
};
