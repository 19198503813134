import React, {FC} from "react";
import {Button} from "@material-ui/core";
import md5 from 'md5';
import {useIntercom} from "react-use-intercom";
import {observer} from "mobx-react";

import {useStores} from "../../store/useStore";
import strings from "../../util/i18n/strings";

import s from './SupportSection.module.scss';

const SupportSection: FC = observer(() => {

  const {userStore: {user}} = useStores();

  const {boot, update, show} = useIntercom();

  const openIntercom = () => {

    if(user?.first_name) {
      boot({
        name: `${user?.first_name}`,
        email: user?.email,
        userId: md5(user?.id || ""),
      })
    } else {
      boot({
        email: user?.email,
        userId: md5(user?.id || ""),
      })
    }
    update({
      hideDefaultLauncher: false
    })

    show();
  }

  return (
    <section className={s.support}>
      <strong>{strings.support_section.got_a_question}</strong>&nbsp;
      <Button color="primary" variant="outlined" onClick={openIntercom}>
        {strings.support_section.contact_support}
      </Button>
      <br/>
    </section>
  )
})

export default SupportSection;
