import React, {FC, useCallback} from "react";

import {TCards, TSubscription} from "../../store/types";
import ActiveDemoSubscription from "./ActiveDemoSubscription";
import NotActiveSubscription from "./NotActiveSubscription";
import ActiveSubscription from "./ActiveSubscription";

type Props = {
  isMobile: boolean,
  item: TSubscription,
  items: TSubscription[],
  cards: TCards[],
  handleChange: (value: string) => void,
  activateSubscription: (id: string | undefined) => void,
  changeStatusSubscription: (status: boolean, id: string) => void,
  loading: boolean,
  link: string,
}

const SubscriptionContent: FC<Props> = ({
                                          link,
                                          item,
                                          items,
                                          cards,
                                          loading,
                                          isMobile,
                                          handleChange,
                                          activateSubscription,
                                          changeStatusSubscription,

                                        }) => {
  const getContent = useCallback(() => {
    if (item.id === '' || !item.active) {
      return (
        <NotActiveSubscription
          link={link}
          item={item}
          items={items}
          loading={loading}
          isMobile={isMobile}
          handleChange={handleChange}
          activateSubscription={activateSubscription}
        />
      )
    }
    if (item.active) {
      if (item.isWelcomeOffer) {
        return (
          <ActiveDemoSubscription
            link={link}
            item={item}
            isMobile={isMobile}
            changeStatusSubscription={changeStatusSubscription}
          />
        )
      } else {
        return (
          <ActiveSubscription
            link={link}
            item={item}
            cards={cards}
            isMobile={isMobile}
            changeStatusSubscription={changeStatusSubscription}
          />
        )
      }
    }
  }, [item, isMobile, items])

  return (
    <>
      {getContent()}
    </>
  )
}

export default SubscriptionContent;
