import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import './style/index.scss';

import strings from "./util/i18n/strings";

const SITE_URL = strings.site_url;

ReactDOM.render(
  <App siteUrl={SITE_URL}/>,
  document.querySelector('#root')
);
