import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useFormik} from "formik";
import {useHistory} from "react-router-dom";
import {Button, CircularProgress, InputAdornment, LinearProgress, MenuItem, Select, TextField} from "@material-ui/core";
//needed import only used func form lodash, because _ import full functions is lib
import throttle from 'lodash/throttle';
import LockIcon from "@material-ui/icons/Lock";

import {useStores} from "../../../store/useStore";
import {countryToFlag} from "../../../util/countries";
import {numberAfterDot, postDOMForm} from "../../../util/utils";
import {ROUTES} from "../../../util/routes";
import {REMITLY_PAYMENT} from "../../../api/root";
import strings from "../../../util/i18n/strings";
import Loader from "../../shared/loader";
import {FieldKey, TOnChangeAmountScreen} from "../helpers";

import s from "../RemitlyPage.module.scss";
import moment from "moment";

const AmountScreen: FC = observer(() => {

  let history = useHistory();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isChange, setIsChange] = useState<boolean>(false);

  const {
    remitlyStore: {
      getFieldValue,
      setFieldValues,
      getPackagesAsync,
      getCalculationsAsync,
      clearCalcData,
      amountClear,
      sendDataAsync,
      errorHandler,
      isLoadingCalc,
      calculator,
      packages,
      fields,
      error,
    },
    modalsStore: {
      changeStateSnackbar
    }
  } = useStores();

  useEffect(() => {
    amountClear();
    if (getFieldValue(FieldKey.FromCountry) === '') {
      history.push(`/${ROUTES.REMITLY}/${ROUTES.DIRECTION}`)
    } else {
      getPackagesAsync()
    }
    return () => {
      clearCalcData();
    }
  }, [])

  useEffect(() => {
    if (error) {
      changeStateSnackbar(error);
      errorHandler('');
      history.push(`/${ROUTES.REMITLY}/${ROUTES.DIRECTION}`)
    }
  }, [error])

  const formik = useFormik({
    initialValues: {
      amount: getFieldValue(FieldKey.Amount),
      toCurrency: getFieldValue(FieldKey.ToCurrency),
      package: getFieldValue(FieldKey.Package),
      receiveAmount: getFieldValue(FieldKey.ReceiveAmount)
    },
    onSubmit: (() => {
      const getValues = JSON.parse(String(localStorage.getItem(REMITLY_PAYMENT)));
      if (getValues && getValues?.firstName && getValues?.lastName && getValues?.email && getValues?.birthDate) {
        setFieldValues({
          firstName: getValues.firstName,
          lastName: getValues.lastName,
          email: getValues.email,
          birthDate: getValues.birthDate
        })
        setLoading(true);
        sendDataAsync()
          .then((res) => {
            const {data} = res;
            postDOMForm(data.paymentUrl, {
              first_name: getValues.firstName,
              last_name: getValues.lastName,
              date_of_birth: getValues.birthDate
            }, 'enctype')
          }).catch((err) => {
          changeStateSnackbar(err)
        })
      } else {
        history.push(`${ROUTES.ROOT}${ROUTES.REMITLY}/${ROUTES.PROFILE.ROOT}/${ROUTES.PROFILE.EMAIL}`)
      }
    })
  })

  const {handleSubmit, values, resetForm, setFieldValue: setVal} = formik;

  const submit = () => {
    handleSubmit()
  }

  const onChange = (value: string, field: TOnChangeAmountScreen) => {
    const fields = {
      ...values
    }
    fields[field] = value;
    if (field === FieldKey.ToCurrency) {
      const findValue = packages.arrayFromTo.find((el) => el.to === value);
      setFieldValues({
        [field]: value,
        fromCurrency: findValue?.from || ''
      })
    } else {
      setFieldValues({
        [field]: value
      })
    }
    if (field === FieldKey.Amount || field === FieldKey.ReceiveAmount) {
      setIsChange(true);
    }
    const amountValue = getFieldValue(FieldKey.Amount)
    const toCurrencyValue = getFieldValue(FieldKey.ToCurrency)
    const packageValue = getFieldValue(FieldKey.Package)
    if (field === FieldKey.Package || field === FieldKey.ToCurrency) {
      throttle(() => getCalculationsAsync(field), 100)()
    }
    //needed remove this if
    if (amountValue && toCurrencyValue && packageValue) {
    } else {
      clearCalcData();
    }
    changeRestForm({...fields})
  }

  const changeRestForm = (changeValues: any) => {
    resetForm({
      values: {...changeValues},
    })
  }

  //mb needed use useCallback (need refactoring)
  const validate = () => {
    let valid = true;
    const amount = getFieldValue(FieldKey.Amount);
    const toCurrency = getFieldValue(FieldKey.ToCurrency);
    const packages = getFieldValue(FieldKey.Package);
    if (amount && toCurrency && packages) {
      valid = false
    }
    if (calculator.status === "none") {
      valid = true
    }
    return valid
  }

  const asyncValue = (field: FieldKey.ToCurrency): string => {
    const value = String(getFieldValue(field));
    const oldValue = values[field];
    if (value !== oldValue) {
      setVal(field, value)
    }
    return value;
  }

  const desc = () => {
    const findValue = packages.list.find((el) => el.package === getFieldValue(FieldKey.Package))
    if (findValue) {
      return findValue.name === 'Economy' ?  strings.amount.economy_description : strings.amount.express_description
    }
    return null
  }

  const getData = (field: FieldKey.Amount | FieldKey.ReceiveAmount) => {
    if (isChange) {
      throttle(() => getCalculationsAsync(field), 100)()
      setIsChange(false)
    }
  }

  const getBonusBlock = (returnValue: boolean) => {
    const calc = Number(calculator.data?.sendAmount) + Number(calculator.data?.commissionAmount) - Number(calculator.data?.chargeAmount)
    if (returnValue) {
      return numberAfterDot(calc);
    } else {
      return numberAfterDot(calc) !== 0 && numberAfterDot(calc) > 0;
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  })

  return (
    <div>
      {getBonusBlock(false) && (
        <div className={s.bonusContainer}>
          <div className={s.bonus}>
            <div className={s.giftBoxIcon}/>
            <div>
              {strings.amount.discount_text_start} {getBonusBlock(true)} {calculator?.data?.sendCurrency} {strings.amount.discount_text_end}
            </div>
          </div>
        </div>
      )}
      {packages.status && (
        <div className={s.wrapperFullLoader}>
          <Loader/>
        </div>
      )}
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={`${s.title} ${s.labelRow}`}>
          {strings.amount.subtitle}
        </div>
        <div className={s.row}>
          <TextField
            fullWidth={true}
            onChange={(e) => {
              onChange(String(e.target.value), FieldKey.Amount)
            }}
            name="amount"
            variant="outlined"
            disabled={isLoadingCalc}
            value={fields.amount}
            type="number"
            onBlur={() => {
              getData(FieldKey.Amount)
            }}
            className={s.customField}
            onInput={(e) => {
              // @ts-ignore
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div className={s.formIcon}>{countryToFlag(String(getFieldValue(FieldKey.FromCountry)))}</div>
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <div className={s.formIcon}>{getFieldValue(FieldKey.FromCurrency)}</div>
                </InputAdornment>
              )
            }}
          />
          {isLoadingCalc && <div className={s.process}><LinearProgress/></div>}
        </div>
        <div className={`${s.title} ${s.labelRow}`}>
          {strings.amount.receive}
        </div>
        <div className={s.row}>
          <TextField
            fullWidth={true}
            name="toCurrency"
            variant="outlined"
            disabled={isLoadingCalc}
            className={s.customField}
            onChange={(e) => {
              onChange(String(e.target.value), FieldKey.ReceiveAmount)
            }}
            onBlur={() => {
              getData(FieldKey.ReceiveAmount)
            }}
            value={fields.receiveAmount}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={s.ignoreDisableColor}>
                  <div className={s.formIcon}>{countryToFlag(String(getFieldValue(FieldKey.ToCountry)))}</div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Select
                    value={asyncValue(FieldKey.ToCurrency)}
                    onChange={(e) => {
                      onChange(String(e.target.value), FieldKey.ToCurrency)
                    }}
                    className={s.select}
                  >
                    {packages.currency.map((el, index) => (
                      <MenuItem value={el} key={index}>{el}</MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              )
            }}
          />
          {isLoadingCalc && <div className={s.process}><LinearProgress/></div>}
        </div>
        <div className={`${s.title} ${s.labelRow}`}>
          {strings.amount.speed}
        </div>
        <div className={s.row}>
          <TextField
            select
            fullWidth={true}
            variant="outlined"
            className={s.customField}
            value={getFieldValue(FieldKey.Package) || ''}
            onChange={(e) => {
              onChange(e.target.value, FieldKey.Package)
            }}
          >
            {packages.types.map((el, index) => (
              <MenuItem value={el} key={index}>
                {el}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          {desc() && (
            <div className={s.desc}>{desc()}</div>
          )}
        </div>
      </form>
      <div className={s.whiteBlock}>
        <div className={s.footerAmount}>
          {calculator.status === 'has' && (
            <div>
              <div className={`${s.row} ${s.shadow}`}>
                <div className={s.box}>
                  <div>{strings.amount.rate}</div>
                  <div>{`1 ${calculator.data?.sendCurrency} = ${calculator.data?.exchangeRate} ${calculator.data?.receiveCurrency}`}</div>
                </div>
                <div>
                  <span>{strings.amount.subtitle}</span>
                  <span>{`${calculator.data?.sendAmount} ${calculator.data?.sendCurrency}`}</span>
                </div>
                <div>
                  <span>{strings.amount.information_item_fee}</span>
                  <span>{`${calculator.data?.commissionAmount} ${calculator.data?.sendCurrency}`}</span>
                </div>
                <div>
                  <span>{strings.amount.discount}</span>
                  <span>{`${(numberAfterDot(Number(calculator.data?.sendAmount) +
                    Number(calculator.data?.commissionAmount) -
                    Number(calculator.data?.chargeAmount)))} 
                  ${calculator.data?.sendCurrency}`}</span>
                </div>
                <div className={s.bold}>
                  <span>{strings.amount.total_cost}</span>
                  <span>
                    {numberAfterDot(Number(calculator?.data?.chargeAmount))} {calculator.data?.sendCurrency}
                </span>
                </div>
              </div>
            </div>
          )}
          {calculator.status === 'loading' && (
            <div className={s.center}>
              <CircularProgress/>
            </div>
          )}
          <div className={s.row}>
            <div className={s.info}>
              <LockIcon/>
              <div>
                {strings.amount.protected_money}
              </div>
            </div>
            {isLoading ?
              <div className={s.wrapProgress}>
                <CircularProgress/>
              </div> :
              <div>
                <Button
                  className={s.submit}
                  variant="contained"
                  color="primary"
                  onClick={submit}
                  disabled={validate() || calculator.status === 'loading'}
                >
                  {strings.buttons_modal.continue_btn}
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
})

export default AmountScreen;

