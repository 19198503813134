import React, {FC} from "react";
import moment from "moment";
import PhoneForwardedRoundedIcon from '@material-ui/icons/PhoneForwardedRounded';
import {getCountryName} from "../../../../util/countries";
import strings from "../../../../util/i18n/strings";

import s from '../../HistorySection.module.scss';

type Props = {
  record: any
}

export const CallRecord: FC<Props> = ({record}) => {
  let flagSrc = `https://static.yollacalls.com/app/flag/${record.phone_country_iso}.png`;
  let durationMins = Math.ceil(record.duration / 60);

  return (
    <tr>
      <td className={s.bold}>
        <div className={s.call}>
          <PhoneForwardedRoundedIcon/>
          <div>{strings.history.row_content_call} +{record.phone}</div>
        </div>
      </td>
      <td>{moment(new Date(record.timestamp * 1000)).format("D MMM HH:mm")}</td>
      <td><img src={flagSrc} className={s.flag} alt=""/><span>{getCountryName(record.phone_country_iso)}</span></td>
      <td>{durationMins} {strings.history.row_content_min}</td>
      <td className={s.rate}>${record.estimate_rate.toFixed(3)}<span>/{strings.history.row_content_min}</span></td>
      <td>${record.amount.toFixed(3)}</td>
    </tr>
  )
};
