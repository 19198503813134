import React, {FC, useEffect} from "react";
import * as yup from "yup";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {Button, TextField} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";

import {useStores} from "../../../../store/useStore";
import {useFormik} from "formik";
import {ROUTES} from "../../../../util/routes";
import strings from "../../../../util/i18n/strings";

import s from "../../RemitlyPage.module.scss";

const validationSchema = yup.object({
  firstName: yup.string().required('Field required'),
  lastName: yup.string().required('Field required'),
})

const NameScreen: FC = observer(() => {

  let history = useHistory();

  const {
    remitlyStore: {
      getFieldValue,
      setFieldValues
    },
    userStore: {
      user
    }
  } = useStores();

  useEffect(() => {
    if (getFieldValue('fromCountry') === '') {
      history.push(`/${ROUTES.REMITLY}/${ROUTES.DIRECTION}`)
    }
  }, [])

  useEffect(() => {
    if(getFieldValue('fromCurrency') === '') {
      history.push(`/${ROUTES.REMITLY}/${ROUTES.AMOUNT}`)
    }
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  })

  const formik = useFormik({
    initialValues: {
      firstName: getFieldValue('firstName'),
      lastName: getFieldValue('lastName')
    },
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values => {
      setFieldValues({firstName: String(values.firstName), lastName: String(values.lastName)});
      history.push(`${ROUTES.ROOT}${ROUTES.REMITLY}/${ROUTES.PROFILE.ROOT}/${ROUTES.PROFILE.BIRTHDAY}`)
    })
  })

  const {handleSubmit, handleChange, values, isValid, resetForm} = formik;

  useEffect(() => {
    if ((user?.first_name && getFieldValue('firstName') === '') || (user?.last_name && getFieldValue('lastName') === '')) {
      changeRestForm({
        firstName: user?.first_name || '',
        lastName: user?.last_name || '',
      })
    }
  }, [])

  const changeRestForm = (changeValues: any, changeErrors: any = {}) => {
    resetForm({
      values: {...changeValues},
      errors: {...changeErrors}
    })
  }

  const getValid = () => {
    const arrayValid = Object.entries(values).filter(([key, value]) => value !== '');
    return arrayValid.length === 0;
  }

  return (
    <div className={s.wrapper}>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={s.title}>
          {strings.name_screen.title}
        </div>
        <div className={s.subtitle}>
          {strings.name_screen.subtitle}
        </div>
        <div className={s.row}>
          <TextField
            fullWidth={true}
            onChange={handleChange}
            name="firstName"
            variant="outlined"
            className={s.customField}
            value={values.firstName}
            label={strings.name_screen.field_label_first_name}
            placeholder={strings.name_screen.field_label_first_name}
          />
        </div>
        <div className={s.row}>
          <TextField
            fullWidth={true}
            onChange={handleChange}
            name="lastName"
            variant="outlined"
            className={s.customField}
            value={values.lastName}
            label={strings.name_screen.field_label_last_name}
            placeholder={strings.name_screen.field_label_last_name}
          />
        </div>
        <div className={s.row}>
          <Button
            type="submit"
            className={s.submit}
            variant="contained"
            color="primary"
            disabled={!isValid || getValid()}
          >
            {strings.buttons_modal.continue_btn}
          </Button>
        </div>
        <div className={s.info}>
          <LockIcon/>
          <div>
            {strings.protected_info}
          </div>
        </div>
      </form>
    </div>
  )
})

export default NameScreen;
