import LocalizedStrings from "react-localization";
import ru from "./strings_ru.json";
import en from "./strings_en.json";
import es from "./strings_es.json";
import fr from "./strings_fr.json";
import de from "./strings_de.json";
import {YOLLA_LANG} from "../../api/root";

const createLocalesStrings = () => {
  let paramLang = new URLSearchParams(window.location.search).get("lang");
  if (paramLang) localStorage.setItem(YOLLA_LANG, paramLang);
  const storedLang = localStorage.getItem(YOLLA_LANG);
  let strings = new LocalizedStrings({en, es, fr, ru, de});
  strings.setLanguage(storedLang ? storedLang : 'en');
  console.log("created LocalizedStrings (" + storedLang + ")")
  return strings;
}

const strings = createLocalesStrings();

export default strings;
