import React, {FC} from "react";
import {getUrlParameter} from "../utils";
import {useStores} from "../../store/useStore";
import strings from "../i18n/strings";
import {useHistory, useLocation} from "react-router-dom";
import {ROUTES} from "../routes";
import {observer} from "mobx-react";

export const UseHookStore: FC<any> = observer(() => {
  const {
    userStore: {
      signByToken,
      clearData,
      stateUser,
    },
    modalsStore: {
      changeStateModal,
      changeStateSnackbar,
      replaceLocationSearch,
    },
    paymentsStore: {
      cards
    }
  } = useStores();
  const history = useHistory();
  const {pathname, search: searchLocation} = useLocation();
  if (getUrlParameter("at")) {
    signByToken(getUrlParameter("at") || '')
      .then(() => {
        const isRemitly = pathname.includes('remitly');
        if(isRemitly) {
          history.push({
            pathname: `/${ROUTES.REMITLY}`,
            search: searchLocation
          })
        }
      })
      .catch((err) => {
        window.location.search = '';
        changeStateSnackbar(err.message)
        clearData()
      })
    replaceLocationSearch(true);
  }
  if (getUrlParameter("result") === "/payment/success") {
    const search = new URLSearchParams(window.location.search);
    const type = search.get('type');
    const newCard = search.get('new');
    if(stateUser) {
      if(cards.length > 0 && type === 'regular' && newCard === '1') {
        changeStateModal(strings.cards_modal.successful_title, true, true, 'cardSuccess');
        replaceLocationSearch(true);
      }
    }
  }
  if (pathname.includes("delete")) {
    if (stateUser) {
      changeStateModal(strings.delete_modal.title, true, true, 'delete');
    }
  }
  const search = new URLSearchParams(window.location.search);
  const unsubscribe = search.get('unsubscribe');
  if (pathname.includes("no") || pathname.includes('unsubscribe') || unsubscribe === '1' || pathname.includes("?unsubscribe")) {
    if (stateUser) {
      changeStateModal(strings.header.menu_account_item__notification, true, true, 'settings');
      if (unsubscribe) {
        replaceLocationSearch(true);
      }
    }
  }

  if (getUrlParameter("result") === "/payment/fail") {
    const message = strings.cards_modal.error_message;
    if(stateUser) {
      if(cards.length > 0) {
        changeStateModal(strings.cards_modal.error_title, true, true, 'cardError', {message});
        replaceLocationSearch(true);
      }
    }
  }
  if (getUrlParameter("promo-code")) {
    const code = getUrlParameter("promo-code");
    changeStateModal(strings.promo_modal.title, true, true, 'promo', {code});
    replaceLocationSearch(true);
  }
  if (getUrlParameter('click_action')) {
    const clickAction = getUrlParameter("click_action");
    if (clickAction === 'promo-code') {
      if (stateUser) {
        const search = new URLSearchParams(window.location.search);
        const code = search.get('code')
        if (code) {
          changeStateModal(strings.promo_modal.title, true, true, 'promo', {code});
        }
        replaceLocationSearch(true);
      }
    }
  }
  return null;
})
