import {makeAutoObservable, runInAction} from "mobx";
import {TCards, TProducts, TCredits} from "./types";
import {paymentAPI} from "../api";

export class PaymentsStore {
  cards: [] | TCards[] = [];
  products: [] | TProducts[] = [];
  credits: [] | TCredits[] = [];
  selectedCard: null | Partial<TCards> = null;

  loading: boolean = true;

  constructor() {
    makeAutoObservable(this)
  }

  get hasCards() {
    return this.cards.length > 0
  }

  setCards = (cards: TCards[] | []) => {
    this.loading = false;
    this.cards = cards;
  }

  setProducts = (products: TProducts[] | []) => {
    this.products = products;
  }

  setCredits = (credits: TCredits[] | []) => {
    this.credits = credits;
  }

  setAutoTopup = (card: TCards | []) => {
    this.selectedCard = Array.isArray(card) ? null : card;
  }

  getCardsAsync = async (getPromise?: boolean) => {
    this.loading = true;
    if(getPromise) {
      return await paymentAPI.getCards()
    }
    await paymentAPI.getCards()
      .then((res) => {
        runInAction(() => {
          this.setCards(res.data)
        })
      })
      .catch(() => {
        runInAction(() => {
          this.setCards([])
        })
      })
  }

  getProductsAsync = async () => {
    await paymentAPI.getProducts()
      .then((res) => {
        runInAction(() => {
          this.setProducts(res.data)
        })
      }).catch(() => {
        runInAction(() => {
          this.setProducts([])
        })
      })
  }

  getAutoTopupAsync = async () => {
    await paymentAPI.getAutoTopup()
      .then((res) => {
        const {data} = res.data;
        runInAction(() => {
          this.setAutoTopup(data)
        })
      }).catch(() => {
        runInAction(() => {
          this.setAutoTopup([])
        })
      })
  }

  updateAmountAsync = async (amount: number, id?: string) => {
    await paymentAPI.updateAmount(id ? id : String(this.selectedCard?.id), amount)
      .then(() => {
        runInAction(() => {
          this.getProductsAsync()
          this.getAutoTopupAsync()
        })
      })
  }

  deleteAutoFill = async () => {
    await paymentAPI.deleteAutoFill(String(this.selectedCard?.id))
      .then(() => {
        runInAction(() => {
          this.getAutoTopupAsync()
        })
      })
  }

  deleteCardAsync = async (id: string) => {
    await paymentAPI.deleteCard(id)
      .then(() => {
        runInAction(() => {
          this.getCardsAsync()
          this.getProductsAsync()
          this.getAutoTopupAsync()
        })
      })
  }

  getCreditsAsync = async () => {
    await paymentAPI.getCredits()
      .then((res) => {
        runInAction(() => {
          this.setCredits(res.data)
        })
      }).catch(() => {
        runInAction(() => {
          this.setCredits([])
        })
      })
  }

  getGatewaysAsync = async (id: string) => {
    const response = await paymentAPI.getGateways(id);
    return response.data;
  }

  openPayAsync = async (id: string, name: string) => {
    const response = await paymentAPI.getLink(id, name);
    return response.data;
  }
}
