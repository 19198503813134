import React, {FC} from "react";
import {observer} from "mobx-react";

import {useStores} from "../../../store/useStore";
import strings from "../../../util/i18n/strings";
import {Button, DialogActions} from "@material-ui/core";

type Props = {
  args?: any
}

const CancelSubscription: FC<Props> = observer(({args}) => {
  const {
    modalsStore: {
      changeStateModal
    },
    subscriptionStore: {
      changeStatusSubscriptionAsync,
      getSubscriptionsAsync
    }
  } = useStores();

  const handleOk = () => {
    changeStatusSubscriptionAsync(args.status, args.id)
      .then(() => {
        getSubscriptionsAsync()
        changeStateModal()
      })
  }

  return (
    <>
      {strings.subscription.modal_text}
      <DialogActions>
        <Button onClick={handleOk} style={{color: '#636A70'}}>
          {strings.subscription.modal_cancel}
        </Button>
        <Button onClick={() => changeStateModal()} style={{color: '#4BCFD0'}}>
          {strings.subscription.modal_dont_cancel}
        </Button>
      </DialogActions>
    </>
  )
})

export default CancelSubscription;
