import React, {FC, useState} from "react";
import {observer} from "mobx-react";
import {Button, InputAdornment, TextField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import {useStores} from "../../../store/useStore";
import strings from "../../../util/i18n/strings";

import s from './DeleteAccountModal.module.scss';
import {privacyInformation} from "../../shared/footer/helper";


type TState = {
  deleteInput: string,
  codeInput: string
}

const DeleteAccountModal: FC = observer(() => {

  const [state, setState] = useState<TState>({
    deleteInput: "",
    codeInput: ""
  })
  const [deleteAcc, setDeleteAcc] = useState<boolean>(false);
  const [errors, setErrors] = useState<TState>({
    deleteInput: "",
    codeInput: ""
  })

  const {
    userStore: {
      sendConfirmAndDeleteAccount,
      deleteAccountConfirm,
    },
    modalsStore: {
      changeStateModal,
    }
  } = useStores();

  const handleDeleteInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({
      ...errors,
      deleteInput: ''
    });
    setState({
      ...state,
      deleteInput: event.target.value
    });
  };

  const handleCodeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      codeInput: event.target.value
    });
  };

  const handleConfirmDeleteRequest = () => {
    const inputValue = state.deleteInput;
    const codeValue = state.codeInput;
    sendConfirmAndDeleteAccount(inputValue, codeValue)
      .catch((err) => {
        setErrors({
          ...errors,
          codeInput: err || ''
        })
      })
  };

  const handleDeleteRequest = () => {
    const inputValue = state.deleteInput;
    deleteAccountConfirm(inputValue)
      .then(() => {
        setDeleteAcc(true);
      }).catch((err) => {
      setErrors({
        ...errors,
        deleteInput: err || ''
      })
    })
  };

  return (
    <>
      <div className={s.textFieldWrapper}>
        <TextField
          variant="filled"
          placeholder={strings.delete_modal.field_label_phone_number}
          label={strings.delete_modal.field_label_phone_number}
          value={state.deleteInput}
          onChange={handleDeleteInput}
          className={s.input}
          helperText={errors.deleteInput ? errors.deleteInput : null}
          error={!!errors.deleteInput}
          disabled={deleteAcc}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AddIcon fontSize="small" className={s.plus}/>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {deleteAcc && (
        <div className={s.marginTP}>
          <TextField
            variant="filled"
            placeholder="XXXX"
            label={strings.delete_modal.field_label_code}
            value={state.codeInput}
            onChange={handleCodeInput}
            className={s.input}
            helperText={errors.codeInput ? errors.codeInput : null}
            error={!!errors.codeInput}
          />
        </div>
      )}
      {deleteAcc ? (
        <div className={s.codeTopic}>
          <p>{strings.delete_modal.confirm_text_1}</p>
          <p>{strings.delete_modal.confirm_text_2}</p>
        </div>
      ) : (
        <div className={s.content}>
          <h3 className={s.aftertopic}>
            {strings.delete_modal.bullets_title}
          </h3>
          <ul className={s.deleteAccountUl}>
            <li>{strings.delete_modal.bullet_1}</li>
            <li>{strings.delete_modal.bullet_2}</li>
            <li>{strings.delete_modal.bullet_3}</li>
          </ul>
          <div className={s.text}>
            <p>{strings.delete_modal.text_start} <a href={`${strings.site_url}/${privacyInformation[1].link}`}>{privacyInformation[1].name}</a>). {strings.delete_modal.text_end}</p>
          </div>
          <h4 className={s.deleteAccountOlTitle}>{strings.delete_modal.steps_title}</h4>
          <ol className={s.deleteAccountOl}>
            <li>{strings.delete_modal.step_1}</li>
            <li>{strings.delete_modal.step_2}</li>
            <li>{strings.delete_modal.step_3}</li>
          </ol>
        </div>
      )}
      <div className={s.deleteButtonsWrapper}>
        <div className={`${s.info}`}>
          {strings.delete_modal.info}
        </div>
        <div>
          <Button onClick={() => changeStateModal()}>
            {strings.buttons_modal.cancel_btn}
          </Button>
          {deleteAcc ? (
            <Button
              color="primary"
              className={s.finalDeleteButton}
              onClick={handleConfirmDeleteRequest}
            >
              {strings.delete_modal.access_btn}
            </Button>
          ) : (
            <Button
              color="primary"
              className={s.deleteButton}
              onClick={handleDeleteRequest}
            >
              {strings.delete_modal.access_btn}
            </Button>
          )}
        </div>
      </div>
    </>
  )
})

export default DeleteAccountModal;
