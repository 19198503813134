import strings from "../../../util/i18n/strings";

export const socialLinks = [
  {
    name: "facebook",
    link: "https://www.facebook.com/yollacalls",
  },
  {
    name: "instagram",
    link: "https://instagram.com/Yollacalls",
  },
  {
    name: "twitter",
    link: "https://twitter.com/yollacalls",
  },
  {
    name: "youtube",
    link: "https://www.youtube.com/channel/UCkbL63j0NCKq_DEkx9F0YMA",
  },

]

const informationLinks = [
  {
    code: "rates",
    name: strings.footer.information_item__rates,
    link: "/rates",
  },
  {
    code: "sms",
    name: strings.footer.information_item__sms,
    link: "/country-codes",
  },
  {
    code: "countryCodes",
    name: strings.footer.information_item__country,
    link: "/country-codes",
  },
  {
    code: "blog",
    name: strings.footer.information_item__blog,
    link: "/blog",
  },
  {
    code: "faq",
    name: strings.footer.information_item__faq,
    link: "/faq"
  },
  {
    code: "contacts",
    name: strings.footer.information_item__contacts,
    link: "/contacts"
  }
]

export const downloadLinks = [
  {
    link: "https://yolla.onelink.me/by6F?af_android_url=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Finternational-calling-yolla%2Fid985702286&af_xp=custom&pid=webaccount-site&is_retargeting=true&af_reengagement_window=30d&deep_link_value=settings&af_dp=yolla%3A%2F%2Fyolla%2Flink%3Fclick_action%3Dsettings&af_web_dp=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Finternational-calling-yolla%2Fid985702286&c=ios",
    style: "appstore"
  },
  {
    link: "https://yolla.onelink.me/by6F?af_ios_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yollacalls&af_xp=custom&pid=webaccount-site&is_retargeting=true&af_reengagement_window=30d&deep_link_value=contacts&af_dp=yolla%3A%2F%2Fyolla%2Flink%3Fclick_action%3Dcontacts&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.yollacalls&c=android",
    style: "googleplay",
  }
]

export const informationColumn = informationLinks.slice(0, 4)
export const helpColumn = informationLinks.slice(4, 6)

export const mobileInformation = informationLinks.slice(0, 1).concat(informationLinks.slice(2, 5))

export const privacyInformation = [
  {
    name: strings.footer.terms_of_use,
    link: "en-tou"
  },
  {
    name: strings.footer.privacy_policy,
    link: "en-privacy"
  }
]


