import React, {FC} from "react";
import {Button, CircularProgress} from "@material-ui/core";

import strings from "../../util/i18n/strings";

import s from './HistorySection.module.scss'

type Props = {
  inProgress: boolean,
  onMoreClick: () => void,
  hasMore: boolean
}

const TableFooter: FC<Props> = ({inProgress, onMoreClick, hasMore}) => (
  <tfoot>
  <tr>
    <td colSpan={6}>
      {inProgress ? (
        <div className={s.footer}>
          <CircularProgress size={30}/>
          <div>
            {strings.history.loading_records}
          </div>
        </div>
      ) : (
        <>
          {hasMore ? (
            <div style={{textAlign: 'right'}}>
              <Button
                variant="contained"
                color="primary"
                disableRipple={true}
                onClick={onMoreClick}
                className={s.showMore}
              >
                {strings.history.show_more_btn}
              </Button>
            </div>
          ) : (
            <div>
              {strings.history.no_items_more}
            </div>
          )}
        </>
      )}
    </td>
  </tr>
  </tfoot>
);

export default TableFooter;
