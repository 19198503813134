export const ROUTES = {
  ROOT: '/',
  LOGIN: 'login',
  PAY: 'payinfo',
  PAYMENT: 'payment',
  SUBSCRIPTION: 'subscription',
  REMITLY: 'remitly',
  DIRECTION: 'direction',
  AMOUNT: 'amount',
  PROFILE: {
    ROOT: 'profile',
    EMAIL: 'email',
    NAME: 'name',
    BIRTHDAY: 'birthday'
  }
}
