import React, {FC} from "react";
import {Button, Icon, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import copy from "copy-to-clipboard";

import {useStores} from "../../store/useStore";
import strings from "../../util/i18n/strings";

import copyIcon from "../../img/copy.svg";

import s from './invite-section.module.scss'

const SHARE_TEXT = "Here's your $2 for calling abroad with Yolla! Follow the link to install the app, top up and get it";

const useStyles = makeStyles({
  shareLink: {
    fontSize: 14,
    width: 256,
    color: '#636A70',
  },
  copy: {
    color: '#636A70',
    minWidth: 88,
    marginLeft: '10px',
  }
})

type Props = {
  link: string
}
const Invite: FC<Props> = ({link}) => {
  const classes = useStyles();
  const {
    modalsStore: {
      changeStateSnackbar
    }
  } = useStores();

  const handleCopyLink = () => {
    copy(link);
    changeStateSnackbar(strings.invite.notify_copy)
  }

  const handleShareFacebook = () => {
    openPopup(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`);
  }

  const handleShareTwitter = () => {
    openPopup(`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=${encodeURIComponent(SHARE_TEXT)}`);
  }

  const openPopup = (link: string): void => {
    window.open(link, "pop", "width=800, height=500, scrollbars=no");
  }
  return (
    <section className={s.invite}>
      <div className={s.toolbar}>
        {strings.invite.title}
      </div>
      <div className={s.content}>
        <p>
          {strings.invite.content_text}
        </p>
        <div className={s.flexItem}>
          <TextField
            variant="filled"
            defaultValue={link}
            className={classes.shareLink}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            disableRipple={true}
            onClick={handleCopyLink}
            className={`${classes.copy} ${s.copyButton}`}
            startIcon={<Icon className={s.copyIcon}>
              <img src={copyIcon} alt=''/>
            </Icon>}
          >
          <span className={s.copy}>
            {strings.invite.copy_btn_text}
          </span>
          </Button>
        </div>
        <div className={s.shareButtons}>
          <a onClick={handleShareFacebook}>
            <div className={s.facebook}/>
          </a>
          <a onClick={handleShareTwitter}>
            <div className={s.twitter}/>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Invite
