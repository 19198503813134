import {instance} from "./root";

export const paymentAPI = {
  getCards() {
    return instance.get("/v2/cards")
  },
  deleteCard(id: string) {
    return instance.delete(`/v2/cards/${id}`)
  },
  getProducts() {
    return instance.get('/v2/auto-topup/products')
  },
  getCredits() {
    return instance.get('/v2/credits/products')
  },
  getAutoTopup() {
    return instance.get('/v2/auto-topup')
  },
  updateAmount(token_id: string, amount: number) {
    return instance.put('/v2/auto-topup', {
      token_id, amount
    })
  },
  deleteAutoFill(id: string) {
    return instance.delete(`/v2/auto-topup/${id}`)
  },
  getGateways(id: string) {
    return instance.get(`/v2/credits/products/${id}/gateways`)
  },
  getLink(productId: string, gateway: string) {
    return instance.post('/v2/credits/payment', {productId, gateway})
  }
}
