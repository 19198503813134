import React, {FC, useLayoutEffect} from "react";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react";
import {useFormik} from "formik";
import {Button, LinearProgress, MenuItem, TextField} from "@material-ui/core";

import {useStores} from "../../../store/useStore";
import {ROUTES} from "../../../util/routes";
import {countryToFlag} from "../../../util/countries";
import {REMITLY_DEF_AMOUNT, REMITLY_FROM_COUNTRY, REMITLY_TO_COUNTRY} from "../../../api/root";
import strings from "../../../util/i18n/strings";

import s from '../RemitlyPage.module.scss'
import {FieldKey} from "../helpers";

const DirectionScreen: FC = observer(() => {

  const {
    remitlyStore: {
      getFieldValue,
      setFieldValues,
      getCountryAsync,
      getTargetsAsync,
      amountClear,
      sources,
      targets,
    },
    userStore: {
      user
    }
  } = useStores();
  let history = useHistory();

  const checkValidate = () => {
    const errors: any = {}
    if (getFieldValue('fromCountry') === '') errors.fromCountry = 'Field required';
    if (getFieldValue('toCountry') === '') errors.toCountry = 'Field required';
    setErrors({
      ...errors
    })
  }

  useLayoutEffect(() => {
    const from = localStorage.getItem(REMITLY_FROM_COUNTRY) || user?.phone_country || '';
    setFieldValues({fromCountry: from});
    getCountryAsync()
    checkValidate()
    const to = localStorage.getItem(REMITLY_TO_COUNTRY);
    if (to) {
      getTargetsAsync(false)
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      fromCountry: getFieldValue(FieldKey.FromCountry),
      toCountry: getFieldValue(FieldKey.ToCountry),
    },
    onSubmit: (values => {
      const defAmount = sources.list.find(i => i.code === values.fromCountry)?.defaultSendAmount || 0;
      localStorage.setItem(REMITLY_FROM_COUNTRY, String(values.fromCountry));
      localStorage.setItem(REMITLY_TO_COUNTRY, String(values.toCountry));
      localStorage.setItem(REMITLY_DEF_AMOUNT, String(defAmount));
      setFieldValues({
        fromCountry: String(values.fromCountry),
        toCountry: String(values.toCountry),
        amount: String(defAmount)
      });
      history.push(`/${ROUTES.REMITLY}/${ROUTES.AMOUNT}`)
    })
  })

  const {handleSubmit, values, resetForm, setErrors} = formik;


  const validate = () => {
    let valid = true;
    const fromCountry = getFieldValue(FieldKey.FromCountry);
    const toCountry = getFieldValue(FieldKey.ToCountry);
    if (fromCountry && toCountry) {
      valid = false
    }
    return valid
  }

  const onChange = (value: string, field: FieldKey.FromCountry | FieldKey.ToCountry) => {
    const fields = {
      ...values
    }
    const errors = {
      fromCountry: '',
      toCountry: ''
    }
    setFieldValues({
      [field]: value
    })
    fields[field] = value;
    if (field === 'fromCountry') {
      getTargetsAsync(true);
      fields.toCountry = '';
      errors.toCountry = 'Field required'
    }
    amountClear();
    changeRestForm({...fields}, {...errors})
  }

  const changeRestForm = (changeValues: any, changeErrors: any = {}) => {
    resetForm({
      values: {...changeValues},
      errors: {...changeErrors}
    })
  }

  return (
    <div className={s.wrapper}>
      <form onSubmit={handleSubmit} className={s.form}>
        <div className={`${s.title} ${s.labelRow}`}>
          {strings.direction.field_label_sending_from}
        </div>
        <div className={s.row}>
          <TextField
            select
            fullWidth={true}
            disabled={sources.status || sources.list.length === 0}
            onChange={(e) =>
              onChange(e.target.value, FieldKey.FromCountry)
            }
            name="fromCountry"
            variant="outlined"
            className={s.customField}
            value={values.fromCountry || ''}
            placeholder={strings.direction.field_label_sending_from}
          >
            {sources.list.map((el, index: any) => (
              <MenuItem key={`${index}-from`} value={el.code}>
                <span className={s.flag}>{countryToFlag(el.code)}</span>
                {el.name}
              </MenuItem>
            ))}
          </TextField>
          {sources.status && <LinearProgress/>}
        </div>
        <div className={`${s.title} ${s.labelRow}`}>
          {strings.direction.field_label_sending_to}
        </div>
        <div className={s.row}>
          <TextField
            select
            fullWidth={true}
            onChange={(e) =>
              onChange(e.target.value, FieldKey.ToCountry)
            }
            disabled={targets.status || targets.list.length === 0}
            name="toCountry"
            variant="outlined"
            className={s.customField}
            value={values.toCountry || ''}
            placeholder={strings.direction.field_label_sending_to}
          >
            {targets.list.map((el, index: any) => (
              <MenuItem key={`${index}-to`} value={el.code}>
                <span className={s.flag}>{countryToFlag(el.code)}</span>
                {el.name}
              </MenuItem>
            ))}
          </TextField>
          {targets.status && <LinearProgress/>}
        </div>
        <div className={s.row}>
          <Button
            type="submit"
            className={s.submit}
            disabled={validate()}
            variant="contained"
            color="primary"
            disableRipple={true}
          >
            {strings.buttons_modal.continue_btn}
          </Button>
        </div>
      </form>
    </div>
  )
})

export default DirectionScreen;
