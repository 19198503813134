import React, {FC} from "react";
import CloseIcon from '@material-ui/icons/Close';

import playButton from '../../../img/google-play2x.png'
import storeButton from '../../../img/appstore2x.png'
import strings from "../../../util/i18n/strings";

import s from './installDialog.module.scss';

type Props = {
  onClose: () => void
}

const InstallDialog: FC<Props> = ({onClose}) => (
  <div className={s.InstallDialog}>
    <div className={s.bg} onClick={onClose}/>
    <div className={s.content}>
      <div className={s.closeIcon} onClick={onClose}>
        <CloseIcon/>
      </div>
      <div className={s.details}>
        <h1>{strings.install_dialog.title}</h1>
        <p>
          {strings.install_dialog.text_start}
          <br/>
          {strings.install_dialog.text_end}
        </p>
        <div className={s.controls}>
          <div className={s.buttons}>
            <a href="https://yollacalls.com/itunes" target="_blank"><img src={storeButton} alt=""/></a><br/>
            <a href="https://yollacalls.com/android" target="_blank"><img src={playButton} alt=""/></a><br/>
          </div>
          <div className={s.qrCode}/>
        </div>
      </div>
    </div>
  </div>
)

export default InstallDialog;
