import React, {FC} from "react";
import {Button} from "@material-ui/core";
import {observer} from "mobx-react";

import warnSrc from "../../img/ic_warn.png";
import {useStores} from "../../store/useStore";
import strings from "../../util/i18n/strings";

import s from './PayInfo.module.scss'

const PayInfo: FC = observer(() => {
  const {userStore: {user}} = useStores();

  const isAuthorized = user !== null;

  return (
    <div className={s.PayInfo}>
      <div>
        <div className={s.banner}>
          <div className={s.img}>
            <img src={warnSrc} alt=""/>
          </div>
          {strings.payinfo.info}
          <div>
            <Button
              variant="contained"
              color="secondary"
              disabled={!isAuthorized}
              href="/"
              className={s.addCredit}>
              {isAuthorized ? strings.payinfo.account_btn : strings.payinfo.loading_btn}
            </Button>
          </div>
        </div>
        <h1>
          {strings.payinfo.content_title}
        </h1>
        <p>
          {strings.payinfo.content_text}
        </p>
      </div>
    </div>
  )
})

export default PayInfo;
