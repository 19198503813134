import React, {FC, useState} from "react";
import {observer} from "mobx-react";
import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel
} from "@material-ui/core";

import {useStores} from "../../../store/useStore";

import s from './SuccessfulCardModal.module.scss'
import strings from "../../../util/i18n/strings";

type TState = {
  autotopupCheckbox: boolean
}

const SuccessfulCardModal: FC = observer(() => {
  const [state, setState] = useState<TState>({
    autotopupCheckbox: true
  })

  const {
    userStore: {
      getRecurringDefaultToken,
      setAutotopupEnabled
    },
    modalsStore: {
      changeStateModal
    },
    paymentsStore: {
      getCardsAsync,
      getProductsAsync,
      getAutoTopupAsync
    },
    subscriptionStore: {
      getSubscriptionsAsync
    }
  } = useStores();

  const handleCompleteDialogOk = () => {
    const {autotopupCheckbox} = state;
    setAutotopupEnabled(autotopupCheckbox);
    changeStateModal();
    getCardsAsync();
    getProductsAsync();
    getAutoTopupAsync();
    getSubscriptionsAsync();
    setTimeout(function () {
      getRecurringDefaultToken();
    }, 1500);
  }

  return (
    <>
      <div style={{height: 10}}/>
      <div className={s.p}>
        <FormControlLabel
          label={strings.cards_modal.field_label_autotopup}
          control={
            <Checkbox
              checked={state.autotopupCheckbox}
              onChange={(e) => setState({...state, autotopupCheckbox: e.target.checked})}
            />
          }
        />
      </div>
      <DialogActions>
        <Button onClick={handleCompleteDialogOk}>
          {strings.buttons_modal.ok_btn}
        </Button>
      </DialogActions>
    </>
  )
})

export default SuccessfulCardModal;
