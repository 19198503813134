export enum MODALS {
  SETTINGS = 'settings',
  LOGOUT = 'logout',
  DOWNLOAD = 'download',
  DELETE = 'delete',
  PROMO = 'promo',
  CARD_SUCCESS = 'cardSuccess',
  CARD_ERROR = 'cardError',
  CODE_SUCCESS = 'successCode',
  CANCEL_SUBSCRIPTION = 'cancelSubscription'
}
