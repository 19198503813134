import axios from "axios";

export const ACCESS_TOKEN_KEY = 'at';
export const DEVICE_ID = 'YOLLA_DEVICE_ID'
export const LOCALSTORAGE_RECURRING_GATEWAY = "recurring_gateway";
export const AUTH_HEADER = 'X-YOLLA-AUTH-TOKEN';
export const YOLLA_LANG = 'YOLLA_LANG';
export const YOLLA_PAYINFO = "YOLLA_PAYINFO";
export const REMITLY_PAYMENT = "REMITLY_PAYMENT";
export const REMITLY_FROM_COUNTRY = "REMITLY_FROM_COUNTRY";
export const REMITLY_TO_COUNTRY = "REMITLY_TO_COUNTRY";
export const REMITLY_DEF_AMOUNT = "REMITLY_DEF_AMOUNT";

export const ERROR_NUMBER_VALID = "number not valid";

// "proxy": "https://api-dev.yollacalls.com",

export const instance = axios.create({
  baseURL: '/api',
  headers: {
    "Access-Control-Allow-Origin": "*",
    'Cache-Control': 'no-cache'
  }
})

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      config.headers[AUTH_HEADER] = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(response => response,
  (err) => {
    let str_err = '';
    if (err?.response?.data?.error) {
      str_err = err.response.data.error.message
    } else {
      str_err = err.error || ''
    }
    return Promise.reject(str_err);
  }
);
