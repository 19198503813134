import React, {FC, useEffect, useLayoutEffect, useMemo} from "react";
import {observer} from "mobx-react";
import {useHistory, useLocation} from "react-router-dom";

import {useStores} from "../../store/useStore";
import {ROUTES} from "../../util/routes";
import {CONSTANTS} from "../../util/utils";
import SubscriptionSection from "../subscriptionSection";
import PaymentSettings from "../paymentSettings";
import AccountSection from "../accountSection";
import HistorySection from "../historySection";
import SupportSection from "../supportSection";
import InviteSection from "../inviteSection";
import CardsSection from "../cardsSection";
import PaymentInfo from "../shared/paymentInfo";
import Loader from "../shared/loader";

import s from './main-page.module.scss';

const MainPage: FC = observer(() => {

  const {
    userStore: {loading, user},
    historyStore: {getHistoryAsync},
    modalsStore: {changeStateSnackbar},
    subscriptionStore: {subscriptions},
  } = useStores();

  useEffect(() => {
    getHistoryAsync()
      .catch((err) => {
        changeStateSnackbar(err)
      })
  }, [])

  const history = useHistory();
  const {search} = useLocation();
  const {loading: loadingSubscription, list} = subscriptions;

  useLayoutEffect(() => {
    const sub = new URLSearchParams(search);
    const offer = sub.get(CONSTANTS.WELCOME_OFFER);
    if (offer) {
      history.push({
        pathname: `/${ROUTES.SUBSCRIPTION}`,
        search: search
      })
    }
  }, [search]);

  const pageIsLoading = useMemo(() => {
    if (!loading) {
      return loadingSubscription
    }
    if (!loadingSubscription) {
      return loading
    }
  }, [loading, loadingSubscription])

  const showContentSubscription = useMemo(() => {
    return list.length > 1
  }, [list])

  return (
    <div className={s.main}>
      {pageIsLoading ?
        <Loader/>
        :
        (user !== null && (
          <>
            <PaymentInfo/>
            <div className={s.top}>
              <AccountSection user={user}/>
              <div className={s.columnPadding}/>
              <PaymentSettings/>
            </div>
            {!showContentSubscription ? (
              <div className={s.centerAlternative}>
                <InviteSection link={user?.ref_link}/>
              </div>
            ) : (
              <div className={s.center}>
                <InviteSection link={user?.ref_link}/>
                <div className={s.columnPadding}/>
                <SubscriptionSection/>
              </div>
            )}
            <CardsSection/>
            <HistorySection/>
            <SupportSection/>
          </>
        ))
      }
    </div>
  )
})

export default MainPage;
