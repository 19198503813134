export const getUrlParameter = (name: any): string | null => {
  if (!name) return null;
  var url = window.location.href;
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? null : results[1];
}


/**
 * create html form and post
 */
export function postDOMForm(url: string, params: any, type?: string) {
  var form = document.createElement("form");
  form.setAttribute("method", "POST");
  form.setAttribute("action", url);
  if (type === 'enctype') {
    form.setAttribute("enctype", 'application/json');
  }
  if(type === undefined) {
    if (url.indexOf("?") !== -1) {
      url.substring(url.indexOf("?")).split("&").forEach(function (part) {
        var item = part.split("=");
        params[item[0]] = decodeURIComponent(item[1]);
      });
    }
  }

  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }
  }
  document.body.appendChild(form);
  form.submit();
}

export const numberAfterDot = (value: number) => {
    return (Math.floor(value * 100) / 100)
}

export const CONSTANTS = {
  WELCOME_OFFER: 'welcome-offer'
}
